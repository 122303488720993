"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Code = void 0;
const createHasher = require('blake2b');
const CODE_HASH_LENGTH = 32;
/**
 * Bytecode of a Smart Contract, as an abstraction.
 */
class Code {
    constructor(hex) {
        this.hex = hex;
    }
    /**
     * Creates a Code object from a buffer (sequence of bytes).
     */
    static fromBuffer(code) {
        return new Code(code.toString("hex"));
    }
    /**
     * Creates a Code object from a hex-encoded string.
     */
    static fromHex(hex) {
        return new Code(hex);
    }
    /**
     * Returns the bytecode as a hex-encoded string.
     */
    toString() {
        return this.hex;
    }
    valueOf() {
        return Buffer.from(this.hex, "hex");
    }
    computeHash() {
        const hash = createHasher(CODE_HASH_LENGTH)
            .update(this.valueOf())
            .digest();
        return Buffer.from(hash);
    }
}
exports.Code = Code;
