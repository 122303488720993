"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TransactionWatcher = void 0;
const asyncTimer_1 = require("./asyncTimer");
const errors_1 = require("./errors");
const logger_1 = require("./logger");
/**
 * TransactionWatcher allows one to continuously watch (monitor), by means of polling, the status of a given transaction.
 */
class TransactionWatcher {
    /**
     * A transaction watcher (awaiter).
     *
     * @param fetcher The transaction fetcher
     * @param options The options
     * @param options.pollingIntervalMilliseconds The polling interval, in milliseconds
     * @param options.timeoutMilliseconds The timeout, in milliseconds
     * @param options.patienceMilliseconds The patience: an extra time (in milliseconds) to wait, after the transaction has reached its desired status. Currently there's a delay between the moment a transaction is marked as "completed" and the moment its outcome (contract results, events and logs) is available.
     */
    constructor(fetcher, options = {}) {
        this.fetcher = new TransactionFetcherWithTracing(fetcher);
        this.pollingIntervalMilliseconds = options.pollingIntervalMilliseconds || TransactionWatcher.DefaultPollingInterval;
        this.timeoutMilliseconds = options.timeoutMilliseconds || TransactionWatcher.DefaultTimeout;
        this.patienceMilliseconds = options.patienceMilliseconds || TransactionWatcher.DefaultPatience;
    }
    /**
     * Waits until the transaction reaches the "pending" status.
     */
    awaitPending(transaction) {
        return __awaiter(this, void 0, void 0, function* () {
            const isPending = (transaction) => transaction.status.isPending();
            const doFetch = () => __awaiter(this, void 0, void 0, function* () { return yield this.fetcher.getTransaction(transaction.getHash().hex()); });
            const errorProvider = () => new errors_1.ErrExpectedTransactionStatusNotReached();
            return this.awaitConditionally(isPending, doFetch, errorProvider);
        });
    }
    /**
      * Waits until the transaction is completely processed.
      */
    awaitCompleted(transaction) {
        return __awaiter(this, void 0, void 0, function* () {
            const isCompleted = (transactionOnNetwork) => {
                if (transactionOnNetwork.isCompleted === undefined) {
                    throw new errors_1.ErrIsCompletedFieldIsMissingOnTransaction();
                }
                return transactionOnNetwork.isCompleted;
            };
            const doFetch = () => __awaiter(this, void 0, void 0, function* () { return yield this.fetcher.getTransaction(transaction.getHash().hex()); });
            const errorProvider = () => new errors_1.ErrExpectedTransactionStatusNotReached();
            return this.awaitConditionally(isCompleted, doFetch, errorProvider);
        });
    }
    awaitAllEvents(transaction, events) {
        return __awaiter(this, void 0, void 0, function* () {
            const foundAllEvents = (transactionOnNetwork) => {
                const allEventIdentifiers = this.getAllTransactionEvents(transactionOnNetwork).map(event => event.identifier);
                const allAreFound = events.every(event => allEventIdentifiers.includes(event));
                return allAreFound;
            };
            const doFetch = () => __awaiter(this, void 0, void 0, function* () { return yield this.fetcher.getTransaction(transaction.getHash().hex()); });
            const errorProvider = () => new errors_1.ErrExpectedTransactionEventsNotFound();
            return this.awaitConditionally(foundAllEvents, doFetch, errorProvider);
        });
    }
    awaitAnyEvent(transaction, events) {
        return __awaiter(this, void 0, void 0, function* () {
            const foundAnyEvent = (transactionOnNetwork) => {
                const allEventIdentifiers = this.getAllTransactionEvents(transactionOnNetwork).map(event => event.identifier);
                const anyIsFound = events.find(event => allEventIdentifiers.includes(event)) != undefined;
                return anyIsFound;
            };
            const doFetch = () => __awaiter(this, void 0, void 0, function* () { return yield this.fetcher.getTransaction(transaction.getHash().hex()); });
            const errorProvider = () => new errors_1.ErrExpectedTransactionEventsNotFound();
            return this.awaitConditionally(foundAnyEvent, doFetch, errorProvider);
        });
    }
    awaitOnCondition(transaction, condition) {
        return __awaiter(this, void 0, void 0, function* () {
            const doFetch = () => __awaiter(this, void 0, void 0, function* () { return yield this.fetcher.getTransaction(transaction.getHash().hex()); });
            const errorProvider = () => new errors_1.ErrExpectedTransactionStatusNotReached();
            return this.awaitConditionally(condition, doFetch, errorProvider);
        });
    }
    awaitConditionally(isSatisfied, doFetch, createError) {
        return __awaiter(this, void 0, void 0, function* () {
            const periodicTimer = new asyncTimer_1.AsyncTimer("watcher:periodic");
            const patienceTimer = new asyncTimer_1.AsyncTimer("watcher:patience");
            const timeoutTimer = new asyncTimer_1.AsyncTimer("watcher:timeout");
            let stop = false;
            let fetchedData = undefined;
            let satisfied = false;
            timeoutTimer.start(this.timeoutMilliseconds).finally(() => {
                timeoutTimer.stop();
                stop = true;
            });
            while (!stop) {
                yield periodicTimer.start(this.pollingIntervalMilliseconds);
                try {
                    fetchedData = yield doFetch();
                    satisfied = isSatisfied(fetchedData);
                    if (satisfied || stop) {
                        break;
                    }
                }
                catch (error) {
                    logger_1.Logger.debug("TransactionWatcher.awaitConditionally(): cannot (yet) fetch data.");
                    if (error instanceof errors_1.ErrIsCompletedFieldIsMissingOnTransaction) {
                        throw error;
                    }
                    if (!(error instanceof errors_1.Err)) {
                        throw error;
                    }
                }
            }
            // The patience timer isn't subject to the timeout constraints.
            if (satisfied) {
                yield patienceTimer.start(this.patienceMilliseconds);
            }
            if (!timeoutTimer.isStopped()) {
                timeoutTimer.stop();
            }
            if (!fetchedData || !satisfied) {
                throw createError();
            }
            return fetchedData;
        });
    }
    getAllTransactionEvents(transaction) {
        const result = [...transaction.logs.events];
        for (const resultItem of transaction.contractResults.items) {
            result.push(...resultItem.logs.events);
        }
        return result;
    }
}
exports.TransactionWatcher = TransactionWatcher;
TransactionWatcher.DefaultPollingInterval = 6000;
TransactionWatcher.DefaultTimeout = TransactionWatcher.DefaultPollingInterval * 15;
TransactionWatcher.DefaultPatience = 0;
TransactionWatcher.NoopOnStatusReceived = (_) => { };
class TransactionFetcherWithTracing {
    constructor(fetcher) {
        this.fetcher = fetcher;
    }
    getTransaction(txHash) {
        return __awaiter(this, void 0, void 0, function* () {
            logger_1.Logger.debug(`transactionWatcher, getTransaction(${txHash})`);
            return yield this.fetcher.getTransaction(txHash);
        });
    }
}
