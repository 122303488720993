"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TokenOperationsFactory = void 0;
const constants_1 = require("../constants");
const logger_1 = require("../logger");
const networkParams_1 = require("../networkParams");
const transaction_1 = require("../transaction");
const transactionPayload_1 = require("../transactionPayload");
const codec_1 = require("./codec");
class TokenOperationsFactory {
    constructor(config) {
        this.config = config;
        this.trueAsHex = codec_1.utf8ToHex("true");
        this.falseAsHex = codec_1.utf8ToHex("false");
    }
    issueFungible(args) {
        this.notifyAboutUnsettingBurnRoleGlobally();
        const parts = [
            "issue",
            codec_1.utf8ToHex(args.tokenName),
            codec_1.utf8ToHex(args.tokenTicker),
            codec_1.bigIntToHex(args.initialSupply),
            codec_1.bigIntToHex(args.numDecimals),
            codec_1.utf8ToHex("canFreeze"),
            args.canFreeze ? this.trueAsHex : this.falseAsHex,
            codec_1.utf8ToHex("canWipe"),
            args.canWipe ? this.trueAsHex : this.falseAsHex,
            codec_1.utf8ToHex("canPause"),
            args.canPause ? this.trueAsHex : this.falseAsHex,
            codec_1.utf8ToHex("canChangeOwner"),
            args.canChangeOwner ? this.trueAsHex : this.falseAsHex,
            codec_1.utf8ToHex("canUpgrade"),
            args.canUpgrade ? this.trueAsHex : this.falseAsHex,
            codec_1.utf8ToHex("canAddSpecialRoles"),
            args.canAddSpecialRoles ? this.trueAsHex : this.falseAsHex
        ];
        return this.createTransaction({
            sender: args.issuer,
            receiver: this.config.esdtContractAddress,
            nonce: args.transactionNonce,
            value: this.config.issueCost,
            gasPrice: args.gasPrice,
            gasLimitHint: args.gasLimit,
            executionGasLimit: this.config.gasLimitIssue,
            dataParts: parts,
        });
    }
    notifyAboutUnsettingBurnRoleGlobally() {
        logger_1.Logger.info(`
==========
IMPORTANT!
==========
You are about to issue (register) a new token. This will set the role "ESDTRoleBurnForAll" (globally).
Once the token is registered, you can unset this role by calling "unsetBurnRoleGlobally" (in a separate transaction).`);
    }
    issueSemiFungible(args) {
        this.notifyAboutUnsettingBurnRoleGlobally();
        const parts = [
            "issueSemiFungible",
            codec_1.utf8ToHex(args.tokenName),
            codec_1.utf8ToHex(args.tokenTicker),
            codec_1.utf8ToHex("canFreeze"),
            args.canFreeze ? this.trueAsHex : this.falseAsHex,
            codec_1.utf8ToHex("canWipe"),
            args.canWipe ? this.trueAsHex : this.falseAsHex,
            codec_1.utf8ToHex("canPause"),
            args.canPause ? this.trueAsHex : this.falseAsHex,
            codec_1.utf8ToHex("canTransferNFTCreateRole"),
            args.canTransferNFTCreateRole ? this.trueAsHex : this.falseAsHex,
            codec_1.utf8ToHex("canChangeOwner"),
            args.canChangeOwner ? this.trueAsHex : this.falseAsHex,
            codec_1.utf8ToHex("canUpgrade"),
            args.canUpgrade ? this.trueAsHex : this.falseAsHex,
            codec_1.utf8ToHex("canAddSpecialRoles"),
            args.canAddSpecialRoles ? this.trueAsHex : this.falseAsHex
        ];
        return this.createTransaction({
            sender: args.issuer,
            receiver: this.config.esdtContractAddress,
            nonce: args.transactionNonce,
            value: this.config.issueCost,
            gasPrice: args.gasPrice,
            gasLimitHint: args.gasLimit,
            executionGasLimit: this.config.gasLimitIssue,
            dataParts: parts,
        });
    }
    issueNonFungible(args) {
        this.notifyAboutUnsettingBurnRoleGlobally();
        const parts = [
            "issueNonFungible",
            codec_1.utf8ToHex(args.tokenName),
            codec_1.utf8ToHex(args.tokenTicker),
            codec_1.utf8ToHex("canFreeze"),
            args.canFreeze ? this.trueAsHex : this.falseAsHex,
            codec_1.utf8ToHex("canWipe"),
            args.canWipe ? this.trueAsHex : this.falseAsHex,
            codec_1.utf8ToHex("canPause"),
            args.canPause ? this.trueAsHex : this.falseAsHex,
            codec_1.utf8ToHex("canTransferNFTCreateRole"),
            args.canTransferNFTCreateRole ? this.trueAsHex : this.falseAsHex,
            codec_1.utf8ToHex("canChangeOwner"),
            args.canChangeOwner ? this.trueAsHex : this.falseAsHex,
            codec_1.utf8ToHex("canUpgrade"),
            args.canUpgrade ? this.trueAsHex : this.falseAsHex,
            codec_1.utf8ToHex("canAddSpecialRoles"),
            args.canAddSpecialRoles ? this.trueAsHex : this.falseAsHex
        ];
        return this.createTransaction({
            sender: args.issuer,
            receiver: this.config.esdtContractAddress,
            nonce: args.transactionNonce,
            value: this.config.issueCost,
            gasPrice: args.gasPrice,
            gasLimitHint: args.gasLimit,
            executionGasLimit: this.config.gasLimitIssue,
            dataParts: parts,
        });
    }
    registerMetaESDT(args) {
        this.notifyAboutUnsettingBurnRoleGlobally();
        const parts = [
            "registerMetaESDT",
            codec_1.utf8ToHex(args.tokenName),
            codec_1.utf8ToHex(args.tokenTicker),
            codec_1.bigIntToHex(args.numDecimals),
            codec_1.utf8ToHex("canFreeze"),
            args.canFreeze ? this.trueAsHex : this.falseAsHex,
            codec_1.utf8ToHex("canWipe"),
            args.canWipe ? this.trueAsHex : this.falseAsHex,
            codec_1.utf8ToHex("canPause"),
            args.canPause ? this.trueAsHex : this.falseAsHex,
            codec_1.utf8ToHex("canTransferNFTCreateRole"),
            args.canTransferNFTCreateRole ? this.trueAsHex : this.falseAsHex,
            codec_1.utf8ToHex("canChangeOwner"),
            args.canChangeOwner ? this.trueAsHex : this.falseAsHex,
            codec_1.utf8ToHex("canUpgrade"),
            args.canUpgrade ? this.trueAsHex : this.falseAsHex,
            codec_1.utf8ToHex("canAddSpecialRoles"),
            args.canAddSpecialRoles ? this.trueAsHex : this.falseAsHex
        ];
        return this.createTransaction({
            sender: args.issuer,
            receiver: this.config.esdtContractAddress,
            nonce: args.transactionNonce,
            value: this.config.issueCost,
            gasPrice: args.gasPrice,
            gasLimitHint: args.gasLimit,
            executionGasLimit: this.config.gasLimitIssue,
            dataParts: parts,
        });
    }
    registerAndSetAllRoles(args) {
        this.notifyAboutUnsettingBurnRoleGlobally();
        const parts = [
            "registerAndSetAllRoles",
            codec_1.utf8ToHex(args.tokenName),
            codec_1.utf8ToHex(args.tokenTicker),
            codec_1.utf8ToHex(args.tokenType),
            codec_1.bigIntToHex(args.numDecimals),
        ];
        return this.createTransaction({
            sender: args.issuer,
            receiver: this.config.esdtContractAddress,
            nonce: args.transactionNonce,
            value: this.config.issueCost,
            gasPrice: args.gasPrice,
            gasLimitHint: args.gasLimit,
            executionGasLimit: this.config.gasLimitIssue,
            dataParts: parts,
        });
    }
    setBurnRoleGlobally(args) {
        const parts = ["setBurnRoleGlobally", codec_1.utf8ToHex(args.tokenIdentifier)];
        return this.createTransaction({
            sender: args.manager,
            receiver: this.config.esdtContractAddress,
            nonce: args.transactionNonce,
            gasPrice: args.gasPrice,
            gasLimitHint: args.gasLimit,
            executionGasLimit: this.config.gasLimitToggleBurnRoleGlobally,
            dataParts: parts,
        });
    }
    unsetBurnRoleGlobally(args) {
        const parts = ["unsetBurnRoleGlobally", codec_1.utf8ToHex(args.tokenIdentifier)];
        return this.createTransaction({
            sender: args.manager,
            receiver: this.config.esdtContractAddress,
            nonce: args.transactionNonce,
            gasPrice: args.gasPrice,
            gasLimitHint: args.gasLimit,
            executionGasLimit: this.config.gasLimitToggleBurnRoleGlobally,
            dataParts: parts,
        });
    }
    setSpecialRoleOnFungible(args) {
        const parts = [
            "setSpecialRole",
            codec_1.utf8ToHex(args.tokenIdentifier),
            codec_1.addressToHex(args.user),
            ...(args.addRoleLocalMint ? [codec_1.utf8ToHex("ESDTRoleLocalMint")] : []),
            ...(args.addRoleLocalBurn ? [codec_1.utf8ToHex("ESDTRoleLocalBurn")] : []),
        ];
        return this.createTransaction({
            sender: args.manager,
            receiver: this.config.esdtContractAddress,
            nonce: args.transactionNonce,
            gasPrice: args.gasPrice,
            gasLimitHint: args.gasLimit,
            executionGasLimit: this.config.gasLimitSetSpecialRole,
            dataParts: parts,
        });
    }
    setSpecialRoleOnSemiFungible(args) {
        const parts = [
            "setSpecialRole",
            codec_1.utf8ToHex(args.tokenIdentifier),
            codec_1.addressToHex(args.user),
            ...(args.addRoleNFTCreate ? [codec_1.utf8ToHex("ESDTRoleNFTCreate")] : []),
            ...(args.addRoleNFTBurn ? [codec_1.utf8ToHex("ESDTRoleNFTBurn")] : []),
            ...(args.addRoleNFTAddQuantity ? [codec_1.utf8ToHex("ESDTRoleNFTAddQuantity")] : []),
            ...(args.addRoleESDTTransferRole ? [codec_1.utf8ToHex("ESDTTransferRole")] : []),
        ];
        return this.createTransaction({
            sender: args.manager,
            receiver: this.config.esdtContractAddress,
            nonce: args.transactionNonce,
            gasPrice: args.gasPrice,
            gasLimitHint: args.gasLimit,
            executionGasLimit: this.config.gasLimitSetSpecialRole,
            dataParts: parts,
        });
    }
    setSpecialRoleOnMetaESDT(args) {
        return this.setSpecialRoleOnSemiFungible(args);
    }
    setSpecialRoleOnNonFungible(args) {
        const parts = [
            "setSpecialRole",
            codec_1.utf8ToHex(args.tokenIdentifier),
            codec_1.addressToHex(args.user),
            ...(args.addRoleNFTCreate ? [codec_1.utf8ToHex("ESDTRoleNFTCreate")] : []),
            ...(args.addRoleNFTBurn ? [codec_1.utf8ToHex("ESDTRoleNFTBurn")] : []),
            ...(args.addRoleNFTUpdateAttributes ? [codec_1.utf8ToHex("ESDTRoleNFTUpdateAttributes")] : []),
            ...(args.addRoleNFTAddURI ? [codec_1.utf8ToHex("ESDTRoleNFTAddURI")] : []),
            ...(args.addRoleESDTTransferRole ? [codec_1.utf8ToHex("ESDTTransferRole")] : []),
        ];
        return this.createTransaction({
            sender: args.manager,
            receiver: this.config.esdtContractAddress,
            nonce: args.transactionNonce,
            gasPrice: args.gasPrice,
            gasLimitHint: args.gasLimit,
            executionGasLimit: this.config.gasLimitSetSpecialRole,
            dataParts: parts,
        });
    }
    nftCreate(args) {
        const parts = [
            "ESDTNFTCreate",
            codec_1.utf8ToHex(args.tokenIdentifier),
            codec_1.bigIntToHex(args.initialQuantity),
            codec_1.utf8ToHex(args.name),
            codec_1.bigIntToHex(args.royalties),
            codec_1.utf8ToHex(args.hash),
            codec_1.bufferToHex(args.attributes),
            ...args.uris.map(codec_1.utf8ToHex),
        ];
        // Note that the following is an approximation (a reasonable one):
        const nftData = args.name + args.hash + args.attributes + args.uris.join("");
        const storageGasLimit = nftData.length * this.config.gasLimitStorePerByte.valueOf();
        return this.createTransaction({
            sender: args.creator,
            receiver: args.creator,
            nonce: args.transactionNonce,
            gasPrice: args.gasPrice,
            gasLimitHint: args.gasLimit,
            executionGasLimit: this.config.gasLimitESDTNFTCreate.valueOf() + storageGasLimit.valueOf(),
            dataParts: parts,
        });
    }
    pause(args) {
        const parts = ["pause", codec_1.utf8ToHex(args.tokenIdentifier)];
        return this.createTransaction({
            sender: args.manager,
            receiver: this.config.esdtContractAddress,
            nonce: args.transactionNonce,
            gasPrice: args.gasPrice,
            gasLimitHint: args.gasLimit,
            executionGasLimit: this.config.gasLimitPausing,
            dataParts: parts,
        });
    }
    unpause(args) {
        const parts = ["unPause", codec_1.utf8ToHex(args.tokenIdentifier)];
        return this.createTransaction({
            sender: args.manager,
            receiver: this.config.esdtContractAddress,
            nonce: args.transactionNonce,
            gasPrice: args.gasPrice,
            gasLimitHint: args.gasLimit,
            executionGasLimit: this.config.gasLimitPausing,
            dataParts: parts,
        });
    }
    freeze(args) {
        const parts = ["freeze", codec_1.utf8ToHex(args.tokenIdentifier), codec_1.addressToHex(args.user)];
        return this.createTransaction({
            sender: args.manager,
            receiver: this.config.esdtContractAddress,
            nonce: args.transactionNonce,
            gasPrice: args.gasPrice,
            gasLimitHint: args.gasLimit,
            executionGasLimit: this.config.gasLimitFreezing,
            dataParts: parts,
        });
    }
    unfreeze(args) {
        const parts = ["unFreeze", codec_1.utf8ToHex(args.tokenIdentifier), codec_1.addressToHex(args.user)];
        return this.createTransaction({
            sender: args.manager,
            receiver: this.config.esdtContractAddress,
            nonce: args.transactionNonce,
            gasPrice: args.gasPrice,
            gasLimitHint: args.gasLimit,
            executionGasLimit: this.config.gasLimitFreezing,
            dataParts: parts,
        });
    }
    wipe(args) {
        const parts = ["wipe", codec_1.utf8ToHex(args.tokenIdentifier), codec_1.addressToHex(args.user)];
        return this.createTransaction({
            sender: args.manager,
            receiver: this.config.esdtContractAddress,
            nonce: args.transactionNonce,
            gasPrice: args.gasPrice,
            gasLimitHint: args.gasLimit,
            executionGasLimit: this.config.gasLimitWiping,
            dataParts: parts,
        });
    }
    localMint(args) {
        const parts = ["ESDTLocalMint", codec_1.utf8ToHex(args.tokenIdentifier), codec_1.bigIntToHex(args.supplyToMint)];
        return this.createTransaction({
            sender: args.manager,
            receiver: args.manager,
            nonce: args.transactionNonce,
            gasPrice: args.gasPrice,
            gasLimitHint: args.gasLimit,
            executionGasLimit: this.config.gasLimitESDTLocalMint,
            dataParts: parts,
        });
    }
    localBurn(args) {
        const parts = ["ESDTLocalBurn", codec_1.utf8ToHex(args.tokenIdentifier), codec_1.bigIntToHex(args.supplyToBurn)];
        return this.createTransaction({
            sender: args.manager,
            receiver: args.manager,
            nonce: args.transactionNonce,
            gasPrice: args.gasPrice,
            gasLimitHint: args.gasLimit,
            executionGasLimit: this.config.gasLimitESDTLocalBurn,
            dataParts: parts,
        });
    }
    updateAttributes(args) {
        const parts = [
            "ESDTNFTUpdateAttributes",
            codec_1.utf8ToHex(args.tokenIdentifier),
            codec_1.bigIntToHex(args.tokenNonce),
            codec_1.bufferToHex(args.attributes),
        ];
        return this.createTransaction({
            sender: args.manager,
            receiver: args.manager,
            nonce: args.transactionNonce,
            gasPrice: args.gasPrice,
            gasLimitHint: args.gasLimit,
            executionGasLimit: this.config.gasLimitESDTNFTUpdateAttributes,
            dataParts: parts,
        });
    }
    addQuantity(args) {
        const parts = [
            "ESDTNFTAddQuantity",
            codec_1.utf8ToHex(args.tokenIdentifier),
            codec_1.bigIntToHex(args.tokenNonce),
            codec_1.bigIntToHex(args.quantityToAdd),
        ];
        return this.createTransaction({
            sender: args.manager,
            receiver: args.manager,
            nonce: args.transactionNonce,
            gasPrice: args.gasPrice,
            gasLimitHint: args.gasLimit,
            executionGasLimit: this.config.gasLimitESDTNFTAddQuantity,
            dataParts: parts,
        });
    }
    burnQuantity(args) {
        const parts = [
            "ESDTNFTBurn",
            codec_1.utf8ToHex(args.tokenIdentifier),
            codec_1.bigIntToHex(args.tokenNonce),
            codec_1.bigIntToHex(args.quantityToBurn),
        ];
        return this.createTransaction({
            sender: args.manager,
            receiver: args.manager,
            nonce: args.transactionNonce,
            gasPrice: args.gasPrice,
            gasLimitHint: args.gasLimit,
            executionGasLimit: this.config.gasLimitESDTNFTBurn,
            dataParts: parts,
        });
    }
    createTransaction({ sender, receiver, nonce, value, gasPrice, gasLimitHint, executionGasLimit, dataParts, }) {
        const payload = this.buildTransactionPayload(dataParts);
        const gasLimit = gasLimitHint || this.computeGasLimit(payload, executionGasLimit);
        const version = new networkParams_1.TransactionVersion(constants_1.TRANSACTION_VERSION_DEFAULT);
        const options = new networkParams_1.TransactionOptions(constants_1.TRANSACTION_OPTIONS_DEFAULT);
        return new transaction_1.Transaction({
            chainID: this.config.chainID,
            sender: sender,
            receiver: receiver,
            gasLimit: gasLimit,
            gasPrice: gasPrice,
            nonce: nonce || 0,
            value: value || 0,
            data: payload,
            version: version,
            options: options,
        });
    }
    buildTransactionPayload(parts) {
        const data = parts.join(constants_1.ARGUMENTS_SEPARATOR);
        return new transactionPayload_1.TransactionPayload(data);
    }
    computeGasLimit(payload, executionGas) {
        const dataMovementGas = this.config.minGasLimit.valueOf() + this.config.gasLimitPerByte.valueOf() * payload.length();
        return dataMovementGas + executionGas.valueOf();
    }
}
exports.TokenOperationsFactory = TokenOperationsFactory;
