"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NullType = exports.TypePlaceholder = exports.isTyped = exports.PrimitiveValue = exports.TypedValue = exports.CustomType = exports.PrimitiveType = exports.TypeCardinality = exports.Type = void 0;
const reflection_1 = require("../../reflection");
const utils_1 = require("../../utils");
/**
 * An abstraction that represents a Type. Handles both generic and non-generic types.
 * Once instantiated as a Type, a generic type is "closed" (as opposed to "open").
 */
class Type {
    constructor(name, typeParameters = [], cardinality = TypeCardinality.fixed(1)) {
        utils_1.guardValueIsSet("name", name);
        this.name = name;
        this.typeParameters = typeParameters;
        this.cardinality = cardinality;
    }
    getName() {
        return this.name;
    }
    getClassName() {
        return Type.ClassName;
    }
    getClassHierarchy() {
        let prototypes = reflection_1.getJavascriptPrototypesInHierarchy(this, prototype => prototype.belongsToTypesystem);
        let classNames = prototypes.map(prototype => prototype.getClassName()).reverse();
        return classNames;
    }
    /**
     * Gets the fully qualified name of the type, to allow for better (efficient and non-ambiguous) type comparison within the custom typesystem.
     */
    getFullyQualifiedName() {
        let joinedTypeParameters = this.getTypeParameters().map(type => type.getFullyQualifiedName()).join(", ");
        return this.isGenericType() ?
            `multiversx:types:${this.getName()}<${joinedTypeParameters}>` :
            `multiversx:types:${this.getName()}`;
    }
    hasExactClass(className) {
        return this.getClassName() == className;
    }
    hasClassOrSuperclass(className) {
        let hierarchy = this.getClassHierarchy();
        return hierarchy.includes(className);
    }
    getTypeParameters() {
        return this.typeParameters;
    }
    isGenericType() {
        return this.typeParameters.length > 0;
    }
    getFirstTypeParameter() {
        utils_1.guardTrue(this.typeParameters.length > 0, "type parameters length > 0");
        return this.typeParameters[0];
    }
    /**
     * Generates type expressions similar to mx-sdk-rs.
     */
    toString() {
        let typeParameters = this.getTypeParameters().map(type => type.toString()).join(", ");
        let typeParametersExpression = typeParameters ? `<${typeParameters}>` : "";
        return `${this.name}${typeParametersExpression}`;
    }
    equals(other) {
        return Type.equals(this, other);
    }
    static equals(a, b) {
        return a.getFullyQualifiedName() == b.getFullyQualifiedName();
    }
    static equalsMany(a, b) {
        return a.every((type, i) => type.equals(b[i]));
    }
    static isAssignableFromMany(a, b) {
        return a.every((type, i) => type.isAssignableFrom(b[i]));
    }
    differs(other) {
        return !this.equals(other);
    }
    valueOf() {
        return this.name;
    }
    /**
     * Inspired from: https://docs.microsoft.com/en-us/dotnet/api/system.type.isassignablefrom
     * For (most) generics, type invariance is expected (assumed) - neither covariance, nor contravariance are supported yet (will be supported in a next release).
     *
     * One exception though: for {@link OptionType}, we simulate covariance for missing (not provided) values.
     * For example, Option<u32> is assignable from Option<?>.
     * For more details, see the implementation of {@link OptionType} and @{@link OptionalType}.
     *
     * Also see:
     *  - https://en.wikipedia.org/wiki/Covariance_and_contravariance_(computer_science)
     *  - https://docs.microsoft.com/en-us/dotnet/standard/generics/covariance-and-contravariance
     */
    isAssignableFrom(other) {
        let invariantTypeParameters = Type.equalsMany(this.getTypeParameters(), other.getTypeParameters());
        if (!invariantTypeParameters) {
            return false;
        }
        let fullyQualifiedNameOfThis = this.getFullyQualifiedName();
        let fullyQualifiedNamesInHierarchyOfOther = Type.getFullyQualifiedNamesInHierarchy(other);
        if (fullyQualifiedNamesInHierarchyOfOther.includes(fullyQualifiedNameOfThis)) {
            return true;
        }
        return other.hasClassOrSuperclass(this.getClassName());
    }
    static getFullyQualifiedNamesInHierarchy(type) {
        let prototypes = reflection_1.getJavascriptPrototypesInHierarchy(type, prototype => prototype.belongsToTypesystem);
        let fullyQualifiedNames = prototypes.map(prototype => prototype.getFullyQualifiedName.call(type));
        return fullyQualifiedNames;
    }
    getNamesOfDependencies() {
        const dependencies = [];
        for (const type of this.typeParameters) {
            dependencies.push(type.getName());
            dependencies.push(...type.getNamesOfDependencies());
        }
        return [...new Set(dependencies)];
    }
    /**
     * Converts the account to a pretty, plain JavaScript object.
     */
    toJSON() {
        return {
            name: this.name,
            typeParameters: this.typeParameters.map(item => item.toJSON())
        };
    }
    getCardinality() {
        return this.cardinality;
    }
    /**
     * A special marker for types within the custom typesystem.
     */
    belongsToTypesystem() { }
}
exports.Type = Type;
Type.ClassName = "Type";
/**
 * TODO: Simplify this class, keep only what is needed.
 *
 * An abstraction for defining and operating with the cardinality of a (composite or simple) type.
 *
 * Simple types (the ones that are directly encodable) have a fixed cardinality: [lower = 1, upper = 1].
 * Composite types (not directly encodable) do not follow this constraint. For example:
 *  - VarArgs: [lower = 0, upper = *]
 *  - OptionalResult: [lower = 0, upper = 1]
 */
class TypeCardinality {
    constructor(lowerBound, upperBound) {
        this.lowerBound = lowerBound;
        this.upperBound = upperBound;
    }
    static fixed(value) {
        return new TypeCardinality(value, value);
    }
    static variable(value) {
        return new TypeCardinality(0, value);
    }
    isSingular() {
        return this.lowerBound == 1 && this.upperBound == 1;
    }
    isSingularOrNone() {
        return this.lowerBound == 0 && this.upperBound == 1;
    }
    isComposite() {
        return this.upperBound != 1;
    }
    isFixed() {
        return this.lowerBound == this.upperBound;
    }
    getLowerBound() {
        return this.lowerBound;
    }
    getUpperBound() {
        return this.upperBound || TypeCardinality.MaxCardinality;
    }
}
exports.TypeCardinality = TypeCardinality;
/**
 * An arbitrarily chosen, reasonably large number.
 */
TypeCardinality.MaxCardinality = 4096;
class PrimitiveType extends Type {
    constructor(name) {
        super(name);
    }
    getClassName() {
        return PrimitiveType.ClassName;
    }
}
exports.PrimitiveType = PrimitiveType;
PrimitiveType.ClassName = "PrimitiveType";
class CustomType extends Type {
    getClassName() {
        return CustomType.ClassName;
    }
}
exports.CustomType = CustomType;
CustomType.ClassName = "CustomType";
class TypedValue {
    constructor(type) {
        this.type = type;
    }
    getClassName() {
        return TypedValue.ClassName;
    }
    getClassHierarchy() {
        let prototypes = reflection_1.getJavascriptPrototypesInHierarchy(this, prototype => prototype.belongsToTypesystem);
        let classNames = prototypes.map(prototype => prototype.getClassName()).reverse();
        return classNames;
    }
    getType() {
        return this.type;
    }
    hasExactClass(className) {
        return this.getClassName() == className;
    }
    hasClassOrSuperclass(className) {
        let hierarchy = this.getClassHierarchy();
        return hierarchy.includes(className);
    }
    /**
     * A special marker for values within the custom typesystem.
     */
    belongsToTypesystem() { }
}
exports.TypedValue = TypedValue;
TypedValue.ClassName = "TypedValue";
class PrimitiveValue extends TypedValue {
    constructor(type) {
        super(type);
    }
    getClassName() {
        return PrimitiveValue.ClassName;
    }
}
exports.PrimitiveValue = PrimitiveValue;
PrimitiveValue.ClassName = "PrimitiveValue";
function isTyped(value) {
    return value.belongsToTypesystem !== undefined;
}
exports.isTyped = isTyped;
class TypePlaceholder extends Type {
    constructor() {
        super("...");
    }
    getClassName() {
        return TypePlaceholder.ClassName;
    }
}
exports.TypePlaceholder = TypePlaceholder;
TypePlaceholder.ClassName = "TypePlaceholder";
class NullType extends Type {
    constructor() {
        super("?");
    }
    getClassName() {
        return NullType.ClassName;
    }
}
exports.NullType = NullType;
NullType.ClassName = "NullType";
