<div *ngIf="content" style="display: inline-block;">
  <div [title]="content"
       *ngIf="!content.startsWith('erd') && !content.startsWith('http') && !content.startsWith('mailto')">
    {{content.substring(0,20)+".."}}
  </div>

  <a *ngIf="content.startsWith('http') || content.startsWith('mailto')" class="mat-body-2"
     target="_blank" [href]="content" [title]="content">
    <ng-content></ng-content>
    {{content.substring(0,60)+"..."}}
  </a>

  <div style="display: inline-block;opacity: 0.8;">
    <mat-icon (click)="short_link()"
              title="raccourcir cette adresse"
              style="cursor: pointer;font-size: medium;margin-bottom: -10px;"
              *ngIf="content.startsWith('http') && redirect_server!=''">
      content_cut
    </mat-icon>

    <mat-icon (click)="open_web()"
              title="Voir le contenu du lien"
              style="cursor: pointer;font-size: medium;margin-bottom: -10px;"
              *ngIf="content.startsWith('http')">
      visibility
    </mat-icon>
  </div>


  <a *ngIf="content.startsWith('erd')" [href]="get_explorer(content,suffix)" target="_blank" [title]="title">
    <span *ngIf="icon==''">{{content.replace(content.substring(7,37),"..")}}</span>
    <mat-icon *ngIf="icon!=''">{{icon}}</mat-icon>
  </a>



</div>
