"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AbiRegistry = void 0;
const errors = __importStar(require("../../errors"));
const utils_1 = require("../../utils");
const endpoint_1 = require("./endpoint");
const enum_1 = require("./enum");
const event_1 = require("./event");
const struct_1 = require("./struct");
const typeMapper_1 = require("./typeMapper");
const interfaceNamePlaceholder = "?";
class AbiRegistry {
    constructor(options) {
        this.endpoints = [];
        this.customTypes = [];
        this.events = [];
        this.name = options.name;
        this.constructorDefinition = options.constructorDefinition;
        this.endpoints = options.endpoints;
        this.customTypes = options.customTypes;
        this.events = options.events || [];
    }
    static create(options) {
        const name = options.name || interfaceNamePlaceholder;
        const constructor = options.constructor || {};
        const endpoints = options.endpoints || [];
        const types = options.types || {};
        const events = options.events || [];
        // Load arbitrary input parameters into properly-defined objects (e.g. EndpointDefinition and CustomType).
        const constructorDefinition = endpoint_1.EndpointDefinition.fromJSON(Object.assign({ name: "constructor" }, constructor));
        const endpointDefinitions = endpoints.map(item => endpoint_1.EndpointDefinition.fromJSON(item));
        const customTypes = [];
        for (const customTypeName in types) {
            const typeDefinition = types[customTypeName];
            if (typeDefinition.type == "struct") {
                customTypes.push(struct_1.StructType.fromJSON({ name: customTypeName, fields: typeDefinition.fields }));
            }
            else if (typeDefinition.type == "enum" || typeDefinition.type == "explicit-enum") {
                customTypes.push(enum_1.EnumType.fromJSON({ name: customTypeName, variants: typeDefinition.variants }));
            }
            else {
                throw new errors.ErrTypingSystem(`Cannot handle custom type: ${customTypeName}`);
            }
        }
        const eventDefinitions = events.map(item => event_1.EventDefinition.fromJSON(item));
        const registry = new AbiRegistry({
            name: name,
            constructorDefinition: constructorDefinition,
            endpoints: endpointDefinitions,
            customTypes: customTypes,
            events: eventDefinitions
        });
        const remappedRegistry = registry.remapToKnownTypes();
        return remappedRegistry;
    }
    getCustomType(name) {
        const result = this.customTypes.find((e) => e.getName() == name);
        utils_1.guardValueIsSetWithMessage(`custom type [${name}] not found`, result);
        return result;
    }
    getStruct(name) {
        const result = this.customTypes.find((e) => e.getName() == name && e.hasExactClass(struct_1.StructType.ClassName));
        utils_1.guardValueIsSetWithMessage(`struct [${name}] not found`, result);
        return result;
    }
    getStructs(names) {
        return names.map((name) => this.getStruct(name));
    }
    getEnum(name) {
        const result = this.customTypes.find((e) => e.getName() == name && e.hasExactClass(enum_1.EnumType.ClassName));
        utils_1.guardValueIsSetWithMessage(`enum [${name}] not found`, result);
        return result;
    }
    getEnums(names) {
        return names.map((name) => this.getEnum(name));
    }
    getEndpoints() {
        return this.endpoints;
    }
    getEndpoint(name) {
        const result = this.endpoints.find((e) => e.name == name);
        utils_1.guardValueIsSetWithMessage(`endpoint [${name}] not found`, result);
        return result;
    }
    getEvent(name) {
        const result = this.events.find((e) => e.identifier == name);
        utils_1.guardValueIsSetWithMessage(`event [${name}] not found`, result);
        return result;
    }
    /**
     * Right after loading ABI definitions into a registry, the endpoints and the custom types (structs, enums)
     * use raw types for their I/O parameters (in the case of endpoints), or for their fields (in the case of structs).
     *
     * A raw type is merely an instance of {@link Type}, with a given name and type parameters (if it's a generic type).
     *
     * Though, for most (development) purposes, we'd like to operate using known, specific types (e.g. {@link List}, {@link U8Type} etc.).
     * This function increases the specificity of the types used by parameter / field definitions within a registry (on best-efforts basis).
     * The result is an equivalent, more explicit ABI registry.
     */
    remapToKnownTypes() {
        const mapper = new typeMapper_1.TypeMapper([]);
        const newCustomTypes = [];
        // First, remap custom types (actually, under the hood, this will remap types of struct fields)
        for (const type of this.customTypes) {
            this.mapCustomTypeDepthFirst(type, this.customTypes, mapper, newCustomTypes);
        }
        if (this.customTypes.length != newCustomTypes.length) {
            throw new errors.ErrTypingSystem("Did not re-map all custom types");
        }
        // Let's remap the constructor:
        const newConstructor = mapEndpoint(this.constructorDefinition, mapper);
        // Then, remap types of all endpoint parameters.
        // The mapper learned all necessary types in the previous step.
        const newEndpoints = [];
        for (const endpoint of this.endpoints) {
            newEndpoints.push(mapEndpoint(endpoint, mapper));
        }
        const newEvents = this.events.map((event) => mapEvent(event, mapper));
        // Now return the new registry, with all types remapped to known types
        const newRegistry = new AbiRegistry({
            name: this.name,
            constructorDefinition: newConstructor,
            endpoints: newEndpoints,
            customTypes: newCustomTypes,
            events: newEvents
        });
        return newRegistry;
    }
    mapCustomTypeDepthFirst(typeToMap, allTypesToMap, mapper, mappedTypes) {
        const hasBeenMapped = mappedTypes.findIndex(type => type.getName() == typeToMap.getName()) >= 0;
        if (hasBeenMapped) {
            return;
        }
        for (const typeName of typeToMap.getNamesOfDependencies()) {
            const dependencyType = allTypesToMap.find(type => type.getName() == typeName);
            if (!dependencyType) {
                // It's a type that we don't have to map (e.g. could be a primitive type).
                continue;
            }
            this.mapCustomTypeDepthFirst(dependencyType, allTypesToMap, mapper, mappedTypes);
        }
        const mappedType = mapper.mapType(typeToMap);
        mappedTypes.push(mappedType);
    }
}
exports.AbiRegistry = AbiRegistry;
function mapEndpoint(endpoint, mapper) {
    const newInput = endpoint.input.map((e) => new endpoint_1.EndpointParameterDefinition(e.name, e.description, mapper.mapType(e.type)));
    const newOutput = endpoint.output.map((e) => new endpoint_1.EndpointParameterDefinition(e.name, e.description, mapper.mapType(e.type)));
    return new endpoint_1.EndpointDefinition(endpoint.name, newInput, newOutput, endpoint.modifiers);
}
function mapEvent(event, mapper) {
    const newInputs = event.inputs.map((e) => new event_1.EventTopicDefinition({
        name: e.name,
        type: mapper.mapType(e.type),
        indexed: e.indexed
    }));
    return new event_1.EventDefinition(event.identifier, newInputs);
}
