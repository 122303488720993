"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.NativeAuthClient = void 0;
const axios_1 = require("axios");
const native_auth_client_config_1 = require("./entities/native.auth.client.config");
class NativeAuthClient {
    constructor(config) {
        this.config = Object.assign(new native_auth_client_config_1.NativeAuthClientConfig(), config);
    }
    getToken(address, token, signature) {
        const encodedAddress = this.encodeValue(address);
        const encodedToken = this.encodeValue(token);
        const accessToken = `${encodedAddress}.${encodedToken}.${signature}`;
        return accessToken;
    }
    initialize(extraInfo = {}) {
        return __awaiter(this, void 0, void 0, function* () {
            const blockHash = yield this.getCurrentBlockHash();
            const encodedExtraInfo = this.encodeValue(JSON.stringify(extraInfo));
            const origin = this.encodeValue(this.config.origin);
            return `${origin}.${blockHash}.${this.config.expirySeconds}.${encodedExtraInfo}`;
        });
    }
    getCurrentBlockHash() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.config.gatewayUrl) {
                return yield this.getCurrentBlockHashWithGateway();
            }
            return yield this.getCurrentBlockHashWithApi();
        });
    }
    getCurrentBlockHashWithGateway() {
        return __awaiter(this, void 0, void 0, function* () {
            const round = yield this.getCurrentRound();
            const url = `${this.config.gatewayUrl}/blocks/by-round/${round}`;
            const response = yield this.get(url);
            const blocks = response.data.data.blocks;
            const block = blocks.filter((block) => block.shard === this.config.blockHashShard)[0];
            return block.hash;
        });
    }
    getCurrentRound() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.config.gatewayUrl) {
                throw new Error("Gateway URL not set");
            }
            if (!this.config.blockHashShard) {
                throw new Error("Blockhash shard not set");
            }
            const url = `${this.config.gatewayUrl}/network/status/${this.config.blockHashShard}`;
            const response = yield this.get(url);
            const status = response.data.data.status;
            return status.erd_current_round;
        });
    }
    getCurrentBlockHashWithApi() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const url = `${this.config.apiUrl}/blocks/latest?ttl=${this.config.expirySeconds}&fields=hash`;
                const response = yield this.get(url);
                if (response.data[0].hash !== undefined) {
                    return response.data[0].hash;
                }
            }
            catch (error) { }
            return this.getCurrentBlockHashWithApiFallback();
        });
    }
    getCurrentBlockHashWithApiFallback() {
        return __awaiter(this, void 0, void 0, function* () {
            let url = `${this.config.apiUrl}/blocks?size=1&fields=hash`;
            if (this.config.blockHashShard !== undefined) {
                url += `&shard=${this.config.blockHashShard}`;
            }
            const response = yield this.get(url);
            return response.data[0].hash;
        });
    }
    encodeValue(str) {
        return this.escape(Buffer.from(str, "utf8").toString("base64"));
    }
    escape(str) {
        return str.replace(/\+/g, "-").replace(/\//g, "_").replace(/=/g, "");
    }
    get(url) {
        return __awaiter(this, void 0, void 0, function* () {
            return yield axios_1.default.get(url, { headers: this.config.extraRequestHeaders });
        });
    }
}
exports.NativeAuthClient = NativeAuthClient;
