<div style="display: inline-block;width:100%;min-width:350px;max-width: 600px;position:relative;height:100vh;">
  <div *ngIf="short_url==''" style="padding:5%;text-align: center;position:relative;height: 100%;">
    <mat-icon (click)="open_about()" class="system_button">help</mat-icon>
    <h1>{{environment.title}}</h1>
    <p>Rediriger vos adresses internet en ajoutant des conditions d'accès</p>
    <br>
    <app-input label="L'adresse internet"
               value_type="text"
               class="app-input-style"
               [showClear]="true" help="Adresse du site de destination"
               [value]="url" (valueChange)="url=$event">
    </app-input>
    <br>



    <mat-form-field class="app-input-style">
      <mat-label>Condition d'accès</mat-label>
      <mat-select required value="redirect" [(ngModel)]="service_selected" autofocus (valueChange)="changeOperation()">
        <mat-option *ngFor="let service of services" [value]="service">{{service.service}}</mat-option>
      </mat-select>
    </mat-form-field>
    <br>


    <mat-expansion-panel class="app-input-style">
      <mat-expansion-panel-header>Options avancées</mat-expansion-panel-header>
      <app-input label="Durée de validité du lien"
                 unity="jours"
                 width="90%" value_type="slider"
                 help="Nombre de jours pendant lesquel le lien fonctionne"
                 [max]="365" [min]="1"
                 [value]="duration" (valueChange)="duration=$event">
      </app-input>

      <app-input label="Message ou redirection hors délai"
                 width="90%" value_type="text"
                 [value]="message" (valueChange)="message=$event">
      </app-input>

    </mat-expansion-panel>

    <br>
    <h5 style="margin-bottom:-3px">Traitement:</h5>
    <p *ngIf="service_selected">{{service_selected.description}}</p>
    <br>
    <br>
    <button mat-raised-button (click)="open_settings()">
      Mes Paramètres
    </button>
    <button mat-raised-button (click)="short()"
            color="primary" *ngIf="url!=''">
      Appliquer le traitement
    </button>
  </div>


  <div *ngIf="short_url!=''">
    <div class="mat-subtitle-1" style="margin-top: 10%;">
      L'utilisateur du lien:<br>
      <a [href]="short_url" target="_blank" class="mat-headline-6 color-primary">
        <strong>{{short_url}}</strong>
      </a>
      <br>
      <h5 style="margin-top:3px"></h5>
      <p *ngIf="service_selected">{{service_selected.description}}</p>
      <br>

      <button mat-raised-button (click)="copy(short_url,'lien copié')">Copier</button>
      <button mat-raised-button (click)="share()" color="primary">Partager</button>

      <div *ngIf="qrcode">
        <br>
        <img [src]="qrcode" style="width: 150px;height:150px;display:inline-block;" (click)="copy(qrcode,'image copiée')"/>
      </div>

      <mat-expansion-panel style="margin-top:10px;width:90%;display: inline-block;" *ngIf="environment.version>0.1">
        <mat-expansion-panel-header>Mode avancé</mat-expansion-panel-header>
        <p style="line-height: 85%;">Pour garantir que votre contenu {{url}} ne soit accessible qu'en passant par votre filtre, insérez le script suivant dans sa page</p>
        <div class="mat-elevation-z8 codezone" >
          {{code}}
        </div>
        <br>
        <button mat-raised-button (click)="copy(code)">Copier</button>
      </mat-expansion-panel>


      <div style="position:absolute;bottom:50px;width:100%;text-align: center;display:block;" >
        <button mat-raised-button (click)="clear()">
          Nouveau
        </button>
      </div>
    </div>
  </div>
</div>


