"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ArgSerializer = void 0;
const constants_1 = require("../constants");
const codec_1 = require("./codec");
const typesystem_1 = require("./typesystem");
const algebraic_1 = require("./typesystem/algebraic");
const composite_1 = require("./typesystem/composite");
const variadic_1 = require("./typesystem/variadic");
// TODO: perhaps move default construction options to a factory (ArgSerializerFactory), instead of referencing them in the constructor
// (postpone as much as possible, breaking change)
const defaultArgSerializerOptions = {
    codec: new codec_1.BinaryCodec()
};
class ArgSerializer {
    constructor(options) {
        options = Object.assign(Object.assign({}, defaultArgSerializerOptions), options);
        this.codec = options.codec;
    }
    /**
     * Reads typed values from an arguments string (e.g. aa@bb@@cc), given parameter definitions.
     */
    stringToValues(joinedString, parameters) {
        let buffers = this.stringToBuffers(joinedString);
        let values = this.buffersToValues(buffers, parameters);
        return values;
    }
    /**
     * Reads raw buffers from an arguments string (e.g. aa@bb@@cc).
     */
    stringToBuffers(joinedString) {
        // We also keep the zero-length buffers (they could encode missing options, Option<T>).
        return joinedString.split(constants_1.ARGUMENTS_SEPARATOR).map(item => Buffer.from(item, "hex"));
    }
    /**
     * Decodes a set of buffers into a set of typed values, given parameter definitions.
     */
    buffersToValues(buffers, parameters) {
        // TODO: Refactor, split (function is quite complex).
        const self = this;
        buffers = buffers || [];
        let values = [];
        let bufferIndex = 0;
        let numBuffers = buffers.length;
        for (let i = 0; i < parameters.length; i++) {
            let parameter = parameters[i];
            let type = parameter.type;
            let value = readValue(type);
            values.push(value);
        }
        // This is a recursive function.
        function readValue(type) {
            if (type.hasExactClass(algebraic_1.OptionalType.ClassName)) {
                const typedValue = readValue(type.getFirstTypeParameter());
                return new algebraic_1.OptionalValue(type, typedValue);
            }
            if (type.hasExactClass(variadic_1.VariadicType.ClassName)) {
                return readVariadicValue(type);
            }
            if (type.hasExactClass(composite_1.CompositeType.ClassName)) {
                const typedValues = [];
                for (const typeParameter of type.getTypeParameters()) {
                    typedValues.push(readValue(typeParameter));
                }
                return new composite_1.CompositeValue(type, typedValues);
            }
            // Non-composite (singular), non-variadic (fixed) type.
            // The only branching without a recursive call.
            const typedValue = decodeNextBuffer(type);
            return typedValue;
        }
        function readVariadicValue(type) {
            const variadicType = type;
            const typedValues = [];
            if (variadicType.isCounted) {
                const count = readValue(new typesystem_1.U32Type()).valueOf().toNumber();
                for (let i = 0; i < count; i++) {
                    typedValues.push(readValue(type.getFirstTypeParameter()));
                }
            }
            else {
                while (!hasReachedTheEnd()) {
                    typedValues.push(readValue(type.getFirstTypeParameter()));
                }
            }
            return new variadic_1.VariadicValue(variadicType, typedValues);
        }
        function decodeNextBuffer(type) {
            if (hasReachedTheEnd()) {
                return null;
            }
            let buffer = buffers[bufferIndex++];
            let decodedValue = self.codec.decodeTopLevel(buffer, type);
            return decodedValue;
        }
        function hasReachedTheEnd() {
            return bufferIndex >= numBuffers;
        }
        return values;
    }
    /**
     * Serializes a set of typed values into an arguments string (e.g. aa@bb@@cc).
     */
    valuesToString(values) {
        let strings = this.valuesToStrings(values);
        let argumentsString = strings.join(constants_1.ARGUMENTS_SEPARATOR);
        let count = strings.length;
        return { argumentsString, count };
    }
    /**
     * Serializes a set of typed values into a set of strings.
     */
    valuesToStrings(values) {
        let buffers = this.valuesToBuffers(values);
        let strings = buffers.map(buffer => buffer.toString("hex"));
        return strings;
    }
    /**
     * Serializes a set of typed values into a set of strings buffers.
     * Variadic types and composite types might result into none, one or more buffers.
     */
    valuesToBuffers(values) {
        // TODO: Refactor, split (function is quite complex).
        const self = this;
        const buffers = [];
        for (const value of values) {
            handleValue(value);
        }
        // This is a recursive function. It appends to the "buffers" variable.
        function handleValue(value) {
            if (value.hasExactClass(algebraic_1.OptionalValue.ClassName)) {
                const valueAsOptional = value;
                if (valueAsOptional.isSet()) {
                    handleValue(valueAsOptional.getTypedValue());
                }
                return;
            }
            if (value.hasExactClass(variadic_1.VariadicValue.ClassName)) {
                handleVariadicValue(value);
                return;
            }
            if (value.hasExactClass(composite_1.CompositeValue.ClassName)) {
                const valueAsComposite = value;
                for (const item of valueAsComposite.getItems()) {
                    handleValue(item);
                }
                return;
            }
            // Non-composite (singular), non-variadic (fixed) type.
            // The only branching without a recursive call.
            const buffer = self.codec.encodeTopLevel(value);
            buffers.push(buffer);
        }
        function handleVariadicValue(value) {
            const variadicType = value.getType();
            if (variadicType.isCounted) {
                const countValue = new typesystem_1.U32Value(value.getItems().length);
                buffers.push(self.codec.encodeTopLevel(countValue));
            }
            for (const item of value.getItems()) {
                handleValue(item);
            }
        }
        return buffers;
    }
}
exports.ArgSerializer = ArgSerializer;
