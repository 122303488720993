"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EventTopicDefinition = exports.EventDefinition = void 0;
const typeExpressionParser_1 = require("./typeExpressionParser");
const NamePlaceholder = "?";
class EventDefinition {
    constructor(identifier, inputs) {
        this.inputs = [];
        this.identifier = identifier;
        this.inputs = inputs || [];
    }
    static fromJSON(json) {
        json.identifier = json.identifier == null ? NamePlaceholder : json.identifier;
        json.inputs = json.inputs || [];
        const inputs = json.inputs.map(param => EventTopicDefinition.fromJSON(param));
        return new EventDefinition(json.identifier, inputs);
    }
}
exports.EventDefinition = EventDefinition;
class EventTopicDefinition {
    constructor(options) {
        this.name = options.name;
        this.type = options.type;
        this.indexed = options.indexed;
    }
    static fromJSON(json) {
        const parsedType = new typeExpressionParser_1.TypeExpressionParser().parse(json.type);
        return new EventTopicDefinition({
            name: json.name || NamePlaceholder,
            type: parsedType,
            indexed: json.indexed
        });
    }
}
exports.EventTopicDefinition = EventTopicDefinition;
