"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ARGUMENTS_SEPARATOR = exports.ESDT_TRANSFER_VALUE = exports.MULTI_ESDTNFT_TRANSFER_FUNCTION_NAME = exports.ESDTNFT_TRANSFER_FUNCTION_NAME = exports.ESDT_TRANSFER_FUNCTION_NAME = exports.ESDT_TRANSFER_GAS_LIMIT = exports.TRANSACTION_VERSION_WITH_OPTIONS = exports.TRANSACTION_VERSION_DEFAULT = exports.TRANSACTION_OPTIONS_TX_GUARDED = exports.TRANSACTION_OPTIONS_TX_HASH_SIGN = exports.TRANSACTION_OPTIONS_DEFAULT = exports.TRANSACTION_MIN_GAS_PRICE = void 0;
exports.TRANSACTION_MIN_GAS_PRICE = 1000000000;
exports.TRANSACTION_OPTIONS_DEFAULT = 0;
exports.TRANSACTION_OPTIONS_TX_HASH_SIGN = 0b0001;
exports.TRANSACTION_OPTIONS_TX_GUARDED = 0b0010;
exports.TRANSACTION_VERSION_DEFAULT = 1;
exports.TRANSACTION_VERSION_WITH_OPTIONS = 2;
exports.ESDT_TRANSFER_GAS_LIMIT = 500000;
exports.ESDT_TRANSFER_FUNCTION_NAME = "ESDTTransfer";
exports.ESDTNFT_TRANSFER_FUNCTION_NAME = "ESDTNFTTransfer";
exports.MULTI_ESDTNFT_TRANSFER_FUNCTION_NAME = "MultiESDTNFTTransfer";
exports.ESDT_TRANSFER_VALUE = "0";
exports.ARGUMENTS_SEPARATOR = "@";
