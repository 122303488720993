"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ErrIsCompletedFieldIsMissingOnTransaction = exports.ErrGasLimitShouldBe0ForInnerTransaction = exports.ErrInvalidRelayedV2BuilderArguments = exports.ErrInvalidRelayedV1BuilderArguments = exports.ErrNotImplemented = exports.ErrContractInteraction = exports.ErrCodec = exports.ErrCannotParseTransactionOutcome = exports.ErrCannotParseContractResults = exports.ErrMissingFieldOnEnum = exports.ErrMissingFieldOnStruct = exports.ErrTypingSystem = exports.ErrMock = exports.ErrContractHasNoAddress = exports.ErrContract = exports.ErrExpectedTransactionEventsNotFound = exports.ErrExpectedTransactionStatusNotReached = exports.ErrTransactionWatcherTimeout = exports.ErrAsyncTimerAborted = exports.ErrAsyncTimerAlreadyRunning = exports.ErrInvalidFunctionName = exports.ErrSignatureCannotCreate = exports.ErrTransactionOptionsInvalid = exports.ErrTransactionVersionInvalid = exports.ErrNonceInvalid = exports.ErrNotEnoughGas = exports.ErrAddressEmpty = exports.ErrAddressBadHrp = exports.ErrAddressCannotCreate = exports.ErrUnexpectedCondition = exports.ErrInvariantFailed = exports.ErrBadType = exports.ErrUnsupportedOperation = exports.ErrInvalidArgument = exports.Err = void 0;
/**
 * The base class for exceptions (errors).
 */
class Err extends Error {
    constructor(message, inner) {
        super(message);
        this.inner = undefined;
        this.inner = inner;
    }
    /**
     * Returns a pretty, friendly summary for the error or for the chain of errros (if appropriate).
     */
    summary() {
        let result = [];
        result.push({ name: this.name, message: this.message });
        let inner = this.inner;
        while (inner) {
            result.push({ name: inner.name, message: inner.message });
            inner = inner.inner;
        }
        return result;
    }
}
exports.Err = Err;
/**
 * Signals invalid arguments for a function, for an operation.
 */
class ErrInvalidArgument extends Err {
    constructor(message, inner) {
        super(`Invalid argument: ${message}`, inner);
    }
}
exports.ErrInvalidArgument = ErrInvalidArgument;
/**
 * Signals an unsupported operation.
 */
class ErrUnsupportedOperation extends Err {
    constructor(operation, reason = "not specified") {
        super(`Operation "${operation}" not supported. Reason: ${reason}`);
    }
}
exports.ErrUnsupportedOperation = ErrUnsupportedOperation;
/**
 * Signals the provisioning of objects of unexpected (bad) types.
 */
class ErrBadType extends Err {
    constructor(name, type, value) {
        super(`Bad type of "${name}": ${value}. Expected type: ${type}`);
    }
}
exports.ErrBadType = ErrBadType;
/**
 * Signals that an invariant failed.
 */
class ErrInvariantFailed extends Err {
    constructor(message) {
        super(`Invariant failed: [${message}]`);
    }
}
exports.ErrInvariantFailed = ErrInvariantFailed;
/**
 * Signals an unexpected condition.
 */
class ErrUnexpectedCondition extends Err {
    constructor(message) {
        super(`Unexpected condition: [${message}]`);
    }
}
exports.ErrUnexpectedCondition = ErrUnexpectedCondition;
/**
 * Signals issues with {@link Address} instantiation.
 */
class ErrAddressCannotCreate extends Err {
    constructor(input, inner) {
        let message = `Cannot create address from: ${input}`;
        super(message, inner);
    }
}
exports.ErrAddressCannotCreate = ErrAddressCannotCreate;
/**
 * Signals issues with the HRP of an {@link Address}.
 */
class ErrAddressBadHrp extends Err {
    constructor(expected, got) {
        super(`Wrong address HRP. Expected: ${expected}, got ${got}`);
    }
}
exports.ErrAddressBadHrp = ErrAddressBadHrp;
/**
 * Signals the presence of an empty / invalid address.
 */
class ErrAddressEmpty extends Err {
    constructor() {
        super(`Address is empty`);
    }
}
exports.ErrAddressEmpty = ErrAddressEmpty;
/**
 * Signals an invalid value for {@link GasLimit} objects.
 */
class ErrNotEnoughGas extends Err {
    constructor(value) {
        super(`Not enough gas provided: ${value}`);
    }
}
exports.ErrNotEnoughGas = ErrNotEnoughGas;
/**
 * Signals an invalid value for {@link Nonce} objects.
 */
class ErrNonceInvalid extends Err {
    constructor(value) {
        super(`Invalid nonce: ${value}`);
    }
}
exports.ErrNonceInvalid = ErrNonceInvalid;
/**
 * Signals an invalid value for {@link TransactionVersion} objects.
 */
class ErrTransactionVersionInvalid extends Err {
    constructor(value) {
        super(`Invalid transaction version: ${value}`);
    }
}
exports.ErrTransactionVersionInvalid = ErrTransactionVersionInvalid;
/**
 * Signals an invalid value for {@link TransactionOptions} objects.
 */
class ErrTransactionOptionsInvalid extends Err {
    constructor(value) {
        super(`Invalid transaction options: ${value}`);
    }
}
exports.ErrTransactionOptionsInvalid = ErrTransactionOptionsInvalid;
/**
 * Signals an error related to signing a message (a transaction).
 */
class ErrSignatureCannotCreate extends Err {
    constructor(input, inner) {
        let message = `Cannot create signature from: ${input}`;
        super(message, inner);
    }
}
exports.ErrSignatureCannotCreate = ErrSignatureCannotCreate;
/**
 * Signals an invalid value for the name of a {@link ContractFunction}.
 */
class ErrInvalidFunctionName extends Err {
    constructor() {
        super(`Invalid function name`);
    }
}
exports.ErrInvalidFunctionName = ErrInvalidFunctionName;
/**
 * Signals a failed operation, since the Timer is already running.
 */
class ErrAsyncTimerAlreadyRunning extends Err {
    constructor() {
        super("Async timer already running");
    }
}
exports.ErrAsyncTimerAlreadyRunning = ErrAsyncTimerAlreadyRunning;
/**
 * Signals a failed operation, since the Timer has been aborted.
 */
class ErrAsyncTimerAborted extends Err {
    constructor() {
        super("Async timer aborted");
    }
}
exports.ErrAsyncTimerAborted = ErrAsyncTimerAborted;
/**
 * Signals a timout for a {@link TransactionWatcher}.
 */
class ErrTransactionWatcherTimeout extends Err {
    constructor() {
        super(`TransactionWatcher has timed out`);
    }
}
exports.ErrTransactionWatcherTimeout = ErrTransactionWatcherTimeout;
/**
 * Signals an issue related to waiting for a specific transaction status.
 */
class ErrExpectedTransactionStatusNotReached extends Err {
    constructor() {
        super(`Expected transaction status not reached`);
    }
}
exports.ErrExpectedTransactionStatusNotReached = ErrExpectedTransactionStatusNotReached;
/**
 * Signals an issue related to waiting for specific transaction events.
 */
class ErrExpectedTransactionEventsNotFound extends Err {
    constructor() {
        super(`Expected transaction events not found`);
    }
}
exports.ErrExpectedTransactionEventsNotFound = ErrExpectedTransactionEventsNotFound;
/**
 * Signals a generic error in the context of Smart Contracts.
 */
class ErrContract extends Err {
    constructor(message) {
        super(message);
    }
}
exports.ErrContract = ErrContract;
class ErrContractHasNoAddress extends ErrContract {
    constructor() {
        super(`
The smart contract has no address set. Make sure you provide the address in the constructor, or call setAddress() appropriately.
If you need to recompute the address of the contract, make use of SmartContract.computeAddress() (static method). 
`);
    }
}
exports.ErrContractHasNoAddress = ErrContractHasNoAddress;
/**
 * Signals an error thrown by the mock-like test objects.
 */
class ErrMock extends Err {
    constructor(message) {
        super(message);
    }
}
exports.ErrMock = ErrMock;
/**
 * Signals a generic type error.
 */
class ErrTypingSystem extends Err {
    constructor(message) {
        super(message);
    }
}
exports.ErrTypingSystem = ErrTypingSystem;
/**
 * Signals a missing field on a struct.
 */
class ErrMissingFieldOnStruct extends Err {
    constructor(fieldName, structName) {
        super(`field ${fieldName} does not exist on struct ${structName}`);
    }
}
exports.ErrMissingFieldOnStruct = ErrMissingFieldOnStruct;
/**
 * Signals a missing field on an enum.
 */
class ErrMissingFieldOnEnum extends Err {
    constructor(fieldName, enumName) {
        super(`field ${fieldName} does not exist on enum ${enumName}`);
    }
}
exports.ErrMissingFieldOnEnum = ErrMissingFieldOnEnum;
/**
 * Signals an error when parsing the contract results.
 */
class ErrCannotParseContractResults extends Err {
    constructor(details) {
        super(`cannot parse contract results: ${details}`);
    }
}
exports.ErrCannotParseContractResults = ErrCannotParseContractResults;
/**
 * Signals an error when parsing the outcome of a transaction (results and logs).
 */
class ErrCannotParseTransactionOutcome extends Err {
    constructor(transactionHash, message) {
        super(`cannot parse outcome of transaction ${transactionHash}: ${message}`);
    }
}
exports.ErrCannotParseTransactionOutcome = ErrCannotParseTransactionOutcome;
/**
 * Signals a generic codec (encode / decode) error.
 */
class ErrCodec extends Err {
    constructor(message) {
        super(message);
    }
}
exports.ErrCodec = ErrCodec;
/**
 * Signals a generic contract interaction error.
 */
class ErrContractInteraction extends Err {
    constructor(message) {
        super(message);
    }
}
exports.ErrContractInteraction = ErrContractInteraction;
/**
 * Signals that a method is not yet implemented
 */
class ErrNotImplemented extends Err {
    constructor() {
        super("Method not yet implemented");
    }
}
exports.ErrNotImplemented = ErrNotImplemented;
/**
 * Signals invalid arguments when using the relayed v1 builder
 */
class ErrInvalidRelayedV1BuilderArguments extends Err {
    constructor() {
        super("invalid arguments for relayed v1 builder");
    }
}
exports.ErrInvalidRelayedV1BuilderArguments = ErrInvalidRelayedV1BuilderArguments;
/**
 * Signals invalid arguments when using the relayed v2 builder
 */
class ErrInvalidRelayedV2BuilderArguments extends Err {
    constructor() {
        super("invalid arguments for relayed v2 builder");
    }
}
exports.ErrInvalidRelayedV2BuilderArguments = ErrInvalidRelayedV2BuilderArguments;
/**
 * Signals that Gas Limit isn't 0 for an inner tx when using relayed v2 builder
 */
class ErrGasLimitShouldBe0ForInnerTransaction extends Err {
    constructor() {
        super("gas limit must be 0 for the inner transaction for relayed v2");
    }
}
exports.ErrGasLimitShouldBe0ForInnerTransaction = ErrGasLimitShouldBe0ForInnerTransaction;
class ErrIsCompletedFieldIsMissingOnTransaction extends Err {
    constructor() {
        super("The transaction watcher requires the `isCompleted` property to be defined on the transaction object. Perhaps you've used the sdk-network-provider's `ProxyNetworkProvider.getTransaction()` and in that case you should also pass `withProcessStatus=true`.");
    }
}
exports.ErrIsCompletedFieldIsMissingOnTransaction = ErrIsCompletedFieldIsMissingOnTransaction;
