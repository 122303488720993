<div style="padding:1%;text-align: center;max-width: 450px;display: inline-block;position:relative;" class="mainform no-scroll-bar" >
  <mat-icon class="system_button" (click)="go_back()">chevron_left</mat-icon>
  <h4>Préférences</h4>

  <app-input value_type="list"
             style="display: inline-block;width:85%;"
             label="Sélectionner un réseau"
             [options]="networks" [value]="network"
             (valueChange)="change_network($event)">
  </app-input>

  <app-input label="Une quantité / un prix" value_type="number"
             style="display: inline-block;width:85%;"
             help="Suivant le service, cette quantité désignera le montant à facturer ou la quantité à distribuer pour un airdrop"
             [value]="quantity" (valueChange)="quantity=$event"></app-input>


  <mat-tab-group [dynamicHeight]="true" style="width: 92%;margin:3%;height:600px;" class="mat-elevation-z8" *ngIf="network">
    <mat-tab label="Wallet" class="no-scroll-bar">
      <p style="margin-top: 20px;">Un wallet peut être utilisé dans les services de facturation pour percevoir les paiements</p>

      <app-input label="Une adresse de wallet"
                 style="width:83%;display: inline-block;"
                 class="app-input-style"
                 value_type="text" [value]="address"
                 (valueChange)="address=$event;">
      </app-input>

      <div style="display:inline-block;width: 91%;" *ngIf="(!address || address=='') && network!.value">
        <app-authent [network]="network.value"
                     size="120px"
                     [walletconnect_open]="false"
                     [connexion]="option_connexion"
                     (authent)="authent($event)">
        </app-authent><br>

      </div>

    </mat-tab>
    <mat-tab label="Tokens">
      <br>
      <p>Par défaut cette crypto sera la monnaie demandé pour les paiements ou distribué via les services d'airdrop</p>
      <app-input label="Crypto préféré" value_type="text" class="app-input-style"
                 help="" icon_action="visibility" (action)="open_explorer($event)"
                 [value]="token" (valueChange)="token=$event"></app-input>

      <div style="width: 350px;height: 250px;display:inline-block;overflow:hidden;" *ngIf="!token || token==''">
        <app-token-selector label_selected="Votre monnaie préférée"
                            (valueChange)="token=$event.id"
                            [network]="network.value">
        </app-token-selector>
      </div>

      <br><br>

      <app-input label="Collection NFT préférée" value_type="text"
                 class="app-input-style"
                 [value]="collection" icon_action="visibility" (action)="open_spotlight($event)"
                 (valueChange)="collection=$event">
      </app-input>

      <!--  <app-collection-selector *ngIf="collection==''"-->
      <!--                           style="width:80%"-->
      <!--                           [network]="network.value"-->
      <!--                           (selected)="collection=$event.id"></app-collection-selector>-->

      <br>
      <button mat-raised-button (click)="open_spotlight()">
        Voir les collections
      </button>

    </mat-tab>
    <mat-tab label="Visuel">
      <br>
      <app-input label="Fond d'écran" value_type="text"
                 class="app-input-style"
                 help="Peut être une image, une couleur, une page web"
                 (action)="call_picture($event)" icon_action="photo"
                 [value]="background" (valueChange)="background=$event"></app-input>

      <img [src]="background" style="width: 300px;height:200px;display:inline;"
           class="mat-elevation-z4"
           *ngIf="background && background.startsWith('http')">
    </mat-tab>

  </mat-tab-group>

  <br>
  <button mat-raised-button (click)="go_back()">
    Retour
  </button>



</div>
