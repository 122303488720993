"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ErrAccountNotConnected = exports.ErrCannotSignSingleTransaction = exports.Err = void 0;
/**
 * The base class for exceptions (errors).
 */
class Err extends Error {
    constructor(message, inner) {
        super(message);
        this.inner = undefined;
        this.inner = inner;
    }
}
exports.Err = Err;
class ErrCannotSignSingleTransaction extends Err {
    constructor() {
        super("Cannot sign single transaction.");
    }
}
exports.ErrCannotSignSingleTransaction = ErrCannotSignSingleTransaction;
class ErrAccountNotConnected extends Err {
    constructor() {
        super("Account is not connected.");
    }
}
exports.ErrAccountNotConnected = ErrAccountNotConnected;
