"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ErrCannotSignedMessage = exports.ErrCannotGetSignedTransactions = void 0;
class ErrCannotGetSignedTransactions extends Error {
    constructor() {
        super("Cannot get signed transaction(s)");
    }
}
exports.ErrCannotGetSignedTransactions = ErrCannotGetSignedTransactions;
class ErrCannotSignedMessage extends Error {
    constructor() {
        super("Cannot get signed message");
    }
}
exports.ErrCannotSignedMessage = ErrCannotSignedMessage;
