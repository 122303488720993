"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WalletConnectV2ProviderErrorMessagesEnum = exports.ErrBadAddress = exports.ErrNotImplemented = exports.Err = void 0;
/**
 * The base class for exceptions (errors).
 */
class Err extends Error {
    constructor(message, inner) {
        super(message);
        this.inner = undefined;
        this.inner = inner;
    }
}
exports.Err = Err;
/**
 * Signals that a method is not yet implemented
 */
class ErrNotImplemented extends Err {
    constructor() {
        super("Method not yet implemented");
    }
}
exports.ErrNotImplemented = ErrNotImplemented;
/**
 * Signals a bad address.
 */
class ErrBadAddress extends Err {
    constructor(value, inner) {
        super(`Bad address: ${value}`, inner);
    }
}
exports.ErrBadAddress = ErrBadAddress;
/**
 * WalletConnect V2 Error Messages
 */
var WalletConnectV2ProviderErrorMessagesEnum;
(function (WalletConnectV2ProviderErrorMessagesEnum) {
    WalletConnectV2ProviderErrorMessagesEnum["unableToInit"] = "WalletConnect is unable to init";
    WalletConnectV2ProviderErrorMessagesEnum["notInitialized"] = "WalletConnect is not initialized";
    WalletConnectV2ProviderErrorMessagesEnum["unableToConnect"] = "WalletConnect is unable to connect";
    WalletConnectV2ProviderErrorMessagesEnum["unableToConnectExisting"] = "WalletConnect is unable to connect to existing pairing";
    WalletConnectV2ProviderErrorMessagesEnum["unableToSignLoginToken"] = "WalletConnect could not sign login token";
    WalletConnectV2ProviderErrorMessagesEnum["unableToSign"] = "WalletConnect could not sign the message";
    WalletConnectV2ProviderErrorMessagesEnum["unableToLogin"] = "WalletConnect is unable to login";
    WalletConnectV2ProviderErrorMessagesEnum["unableToHandleTopic"] = "WalletConnect: Unable to handle topic update";
    WalletConnectV2ProviderErrorMessagesEnum["unableToHandleEvent"] = "WalletConnect: Unable to handle events";
    WalletConnectV2ProviderErrorMessagesEnum["unableToHandleCleanup"] = "WalletConnect: Unable to handle cleanup";
    WalletConnectV2ProviderErrorMessagesEnum["sessionNotConnected"] = "WalletConnect Session is not connected";
    WalletConnectV2ProviderErrorMessagesEnum["sessionDeleted"] = "WalletConnect Session Deleted";
    WalletConnectV2ProviderErrorMessagesEnum["sessionExpired"] = "WalletConnect Session Expired";
    WalletConnectV2ProviderErrorMessagesEnum["alreadyLoggedOut"] = "WalletConnect: Already logged out";
    WalletConnectV2ProviderErrorMessagesEnum["pingFailed"] = "WalletConnect Ping Failed";
    WalletConnectV2ProviderErrorMessagesEnum["invalidAddress"] = "WalletConnect: Invalid address";
    WalletConnectV2ProviderErrorMessagesEnum["requestDifferentChain"] = "WalletConnect: Request Chain Id different than Connection Chain Id";
    WalletConnectV2ProviderErrorMessagesEnum["invalidMessageResponse"] = "WalletConnect could not sign the message";
    WalletConnectV2ProviderErrorMessagesEnum["invalidMessageSignature"] = "WalletConnect: Invalid message signature";
    WalletConnectV2ProviderErrorMessagesEnum["invalidTransactionResponse"] = "WalletConnect could not sign the transactions. Invalid signatures";
    WalletConnectV2ProviderErrorMessagesEnum["invalidCustomRequestResponse"] = "WalletConnect could not send the custom request";
    WalletConnectV2ProviderErrorMessagesEnum["transactionError"] = "Transaction canceled";
    WalletConnectV2ProviderErrorMessagesEnum["connectionError"] = "WalletConnect could not establish a connection";
    WalletConnectV2ProviderErrorMessagesEnum["invalidGuardian"] = "WalletConnect: Invalid Guardian";
})(WalletConnectV2ProviderErrorMessagesEnum = exports.WalletConnectV2ProviderErrorMessagesEnum || (exports.WalletConnectV2ProviderErrorMessagesEnum = {}));
