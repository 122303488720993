import { __awaiter, __decorate, __metadata } from 'tslib';
import { ɵɵdefineInjectable, Injectable } from '@angular/core';

let NgNavigatorShareService = class NgNavigatorShareService {
    constructor() {
        this.webNavigator = null;
        this.webNavigator = window.navigator;
    }
    canShare() {
        return this.webNavigator !== null && this.webNavigator.share !== undefined;
    }
    canShareFile(file) {
        return this.webNavigator !== null && this.webNavigator.share !== undefined && this.webNavigator.canShare({ files: file });
    }
    share({ title, text, url, files }) {
        return new Promise((resolve, reject) => __awaiter(this, void 0, void 0, function* () {
            if (this.webNavigator !== null && this.webNavigator.share !== undefined) {
                if ((text === undefined || text === null) &&
                    (url === undefined || url === null)) {
                    console.warn(`text and url both can't be empty, at least provide either text or url`);
                }
                else {
                    try {
                        const shareObject = {
                            title,
                            text,
                            url
                        };
                        if (files && files.length !== 0) {
                            shareObject.files = files;
                        }
                        const isShared = yield this.webNavigator.share(shareObject);
                        resolve({
                            shared: true
                        });
                    }
                    catch (error) {
                        reject({
                            shared: false,
                            error
                        });
                    }
                }
            }
            else {
                reject({
                    shared: false,
                    error: `This service/api is not supported in your Browser`
                });
            }
        }));
    }
};
NgNavigatorShareService.ɵprov = ɵɵdefineInjectable({ factory: function NgNavigatorShareService_Factory() { return new NgNavigatorShareService(); }, token: NgNavigatorShareService, providedIn: "root" });
NgNavigatorShareService = __decorate([
    Injectable({
        providedIn: 'root'
    }),
    __metadata("design:paramtypes", [])
], NgNavigatorShareService);

/*
 * Public API Surface of ng-navigator-share
 */

/**
 * Generated bundle index. Do not edit.
 */

export { NgNavigatorShareService };

