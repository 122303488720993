<div *ngIf="type_animation=='crossfade'" [style.height]="size" style="border: none;">
  <div style="display: inline-block;position: relative;padding:0;margin:0;" *ngIf="image_to_show"
       [style.width]="size" [style.height]="size"  [style]="transition" >
    <div [style.border]="border" style="width: 100%;height: 100%;margin:0;padding:0;">
      <img [src]="image_to_show"
           *ngIf="!image_to_show?.endsWith('mp4')"
           style="width: 100%;height: 100%;"
           alt="Cette image n'est pas disponible">
      <video muted autoplay *ngIf="image_to_show?.endsWith('mp4')" [style.width]="size">
        <source [src]="image_to_show" type='video/mp4' />
      </video>
    </div>

    <div style="position:absolute;top:10px;left:10px;" *ngIf="show_title">{{title}}</div>
  </div>
</div>

<div *ngIf="type_animation=='show'" >
      <img [src]="image_to_show"
           *ngIf="!image_to_show?.endsWith('mp4')"
           [style.height]="size" [style.width]="size"
           class="mat-subheading-1"
           alt="Cette image n'est pas disponible">
  <video muted autoplay *ngIf="image_to_show?.endsWith('mp4')" [style.width]="size" >
    <source [src]="image_to_show" type='video/mp4'/>
  </video>
</div>


<div *ngIf="type_animation=='mosaic'">
  <div style="display: inline-block;position: relative;padding:0;margin:0;overflow: hidden;"
       *ngFor="let nft of nfts" [style.width]="size" [style.height]="size" >
    <div [style.border]="border" style="width: 100%;height: 100%;margin:0;">
      <img [src]="nft.visual"
           style="width: 100%;height: 100%;"
           class="mat-subheading-1"
           alt="Cette image n'est pas disponible">
    </div>
  </div>
</div>



