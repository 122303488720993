"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RelayedTransactionV2Builder = void 0;
const errors_1 = require("./errors");
const smartcontracts_1 = require("./smartcontracts");
const transaction_1 = require("./transaction");
const transactionPayload_1 = require("./transactionPayload");
class RelayedTransactionV2Builder {
    /**
     * Sets the inner transaction to be used. It has to be already signed and with gasLimit set to 0. These checks
     * are performed on the build() method
     *
     * @param {Transaction} transaction The inner transaction to be used
     */
    setInnerTransaction(transaction) {
        this.innerTransaction = transaction;
        return this;
    }
    /**
     * Sets the gas limit to be used for the SC Call inside the inner transaction
     *
     * @param {IGasLimit} gasLimit The gas limit to be used. The inner transaction needs to have the gas limit set to 0,
     * so this field will specify the gas to be used for the SC call of the inner transaction
     */
    setInnerTransactionGasLimit(gasLimit) {
        this.innerTransactionGasLimit = gasLimit;
        return this;
    }
    /**
     * Sets the network config to be used for building the relayed v2 transaction
     *
     * @param {INetworkConfig} netConfig The network configuration to be used
     */
    setNetworkConfig(netConfig) {
        this.netConfig = netConfig;
        return this;
    }
    /**
     * Sets the address of the relayer (the one that will actually pay the fee)
     *
     * @param relayerAddress
     */
    setRelayerAddress(relayerAddress) {
        this.relayerAddress = relayerAddress;
        return this;
    }
    /**
     * (optional) Sets the nonce of the relayer
     *
     * @param relayerNonce
     */
    setRelayerNonce(relayerNonce) {
        this.relayerNonce = relayerNonce;
        return this;
    }
    /**
     * Tries to build the relayed v2 transaction based on the previously set fields.
     * It returns a transaction that isn't signed
     *
     * @throws ErrInvalidRelayedV2BuilderArguments
     * @throws ErrGasLimitShouldBe0ForInnerTransaction
     * @return Transaction
     */
    build() {
        if (!this.innerTransaction || !this.innerTransactionGasLimit || !this.relayerAddress || !this.netConfig || !this.innerTransaction.getSignature()) {
            throw new errors_1.ErrInvalidRelayedV2BuilderArguments();
        }
        if (this.innerTransaction.getGasLimit() != 0) {
            throw new errors_1.ErrGasLimitShouldBe0ForInnerTransaction();
        }
        const { argumentsString } = new smartcontracts_1.ArgSerializer().valuesToString([
            new smartcontracts_1.AddressValue(this.innerTransaction.getReceiver()),
            new smartcontracts_1.U64Value(this.innerTransaction.getNonce().valueOf()),
            new smartcontracts_1.BytesValue(this.innerTransaction.getData().valueOf()),
            new smartcontracts_1.BytesValue(this.innerTransaction.getSignature())
        ]);
        const data = `relayedTxV2@${argumentsString}`;
        const payload = new transactionPayload_1.TransactionPayload(data);
        let relayedTransaction = new transaction_1.Transaction({
            sender: this.relayerAddress,
            receiver: this.innerTransaction.getSender(),
            value: 0,
            gasLimit: this.innerTransactionGasLimit.valueOf() + this.netConfig.MinGasLimit + this.netConfig.GasPerDataByte * payload.length(),
            data: payload,
            chainID: this.netConfig.ChainID,
        });
        if (this.relayerNonce) {
            relayedTransaction.setNonce(this.relayerNonce);
        }
        return relayedTransaction;
    }
}
exports.RelayedTransactionV2Builder = RelayedTransactionV2Builder;
