"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WALLETCONNECT_SIGN_LOGIN_DELAY = exports.WALLETCONNECT_MULTIVERSX_OPTIONAL_METHODS = exports.WALLETCONNECT_MULTIVERSX_METHODS = exports.WALLETCONNECT_MULTIVERSX_NAMESPACE = void 0;
const operation_1 = require("./operation");
// WalletConnect Namespace for MultiversX
exports.WALLETCONNECT_MULTIVERSX_NAMESPACE = "mvx";
// WalletConnect default methods for Multiversx
exports.WALLETCONNECT_MULTIVERSX_METHODS = Object.values(operation_1.Operation);
// WalletConnect optional methods for Multiversx
exports.WALLETCONNECT_MULTIVERSX_OPTIONAL_METHODS = Object.values(operation_1.OptionalOperation);
// Delay the sign login token action for 500ms to allow the UI to update properly
exports.WALLETCONNECT_SIGN_LOGIN_DELAY = 500;
