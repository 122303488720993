"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.sleep = exports.getMetadata = exports.applyTransactionSignature = exports.getAddressFromSession = exports.addressIsValid = exports.getConnectionParams = exports.getCurrentTopic = exports.getCurrentSession = void 0;
const sdk_core_1 = require("@multiversx/sdk-core");
const signature_1 = require("@multiversx/sdk-core/out/signature");
const utils_1 = require("@walletconnect/utils");
const constants_1 = require("./constants");
const errors_1 = require("./errors");
const logger_1 = require("./logger");
const operation_1 = require("./operation");
function getCurrentSession(chainId, client) {
    if (!client) {
        throw new Error(errors_1.WalletConnectV2ProviderErrorMessagesEnum.notInitialized);
    }
    const acknowledgedSessions = client
        .find(getConnectionParams(chainId))
        .filter((s) => s.acknowledged);
    if (acknowledgedSessions.length > 0) {
        const lastKeyIndex = acknowledgedSessions.length - 1;
        const session = acknowledgedSessions[lastKeyIndex];
        return session;
    }
    if (client.session.length > 0) {
        const lastKeyIndex = client.session.keys.length - 1;
        const session = client.session.get(client.session.keys[lastKeyIndex]);
        return session;
    }
    logger_1.Logger.error(errors_1.WalletConnectV2ProviderErrorMessagesEnum.sessionNotConnected);
    throw new Error(errors_1.WalletConnectV2ProviderErrorMessagesEnum.sessionNotConnected);
}
exports.getCurrentSession = getCurrentSession;
function getCurrentTopic(chainId, client) {
    if (!client) {
        throw new Error(errors_1.WalletConnectV2ProviderErrorMessagesEnum.notInitialized);
    }
    const session = getCurrentSession(chainId, client);
    if (!(session === null || session === void 0 ? void 0 : session.topic)) {
        throw new Error(errors_1.WalletConnectV2ProviderErrorMessagesEnum.sessionNotConnected);
    }
    return session.topic;
}
exports.getCurrentTopic = getCurrentTopic;
function getConnectionParams(chainId, options) {
    var _a, _b, _c;
    const methods = [
        ...constants_1.WALLETCONNECT_MULTIVERSX_METHODS,
        ...((_a = options === null || options === void 0 ? void 0 : options.methods) !== null && _a !== void 0 ? _a : []),
    ];
    if (!((_b = options === null || options === void 0 ? void 0 : options.methods) === null || _b === void 0 ? void 0 : _b.includes(operation_1.OptionalOperation.SIGN_LOGIN_TOKEN))) {
        methods.push(operation_1.OptionalOperation.SIGN_LOGIN_TOKEN);
    }
    const chains = [`${constants_1.WALLETCONNECT_MULTIVERSX_NAMESPACE}:${chainId}`];
    const events = (_c = options === null || options === void 0 ? void 0 : options.events) !== null && _c !== void 0 ? _c : [];
    return {
        requiredNamespaces: {
            [constants_1.WALLETCONNECT_MULTIVERSX_NAMESPACE]: {
                methods,
                chains,
                events,
            },
        },
    };
}
exports.getConnectionParams = getConnectionParams;
function addressIsValid(destinationAddress) {
    try {
        const address = sdk_core_1.Address.fromBech32(destinationAddress);
        return !address.isEmpty();
    }
    catch (_a) {
        return false;
    }
}
exports.addressIsValid = addressIsValid;
function getAddressFromSession(session) {
    const selectedNamespace = session.namespaces[constants_1.WALLETCONNECT_MULTIVERSX_NAMESPACE];
    if (selectedNamespace && selectedNamespace.accounts) {
        // Use only the first address in case of multiple provided addresses
        const currentSession = selectedNamespace.accounts[0];
        const [namespace, reference, address] = currentSession.split(":");
        return address;
    }
    return "";
}
exports.getAddressFromSession = getAddressFromSession;
function applyTransactionSignature({ transaction, response, }) {
    if (!response) {
        logger_1.Logger.error(errors_1.WalletConnectV2ProviderErrorMessagesEnum.invalidTransactionResponse);
        throw new Error(errors_1.WalletConnectV2ProviderErrorMessagesEnum.invalidTransactionResponse);
    }
    const { signature, guardianSignature, version, options, guardian } = response;
    const transactionGuardian = transaction.getGuardian().bech32();
    if (transactionGuardian && transactionGuardian !== guardian) {
        logger_1.Logger.error(errors_1.WalletConnectV2ProviderErrorMessagesEnum.invalidGuardian);
        throw new Error(errors_1.WalletConnectV2ProviderErrorMessagesEnum.invalidGuardian);
    }
    if (guardian) {
        transaction.setGuardian(sdk_core_1.Address.fromBech32(guardian));
    }
    if (version) {
        transaction.setVersion(version);
    }
    if (options != null) {
        transaction.setOptions(options);
    }
    transaction.applySignature(new signature_1.Signature(signature));
    if (guardianSignature) {
        transaction.applyGuardianSignature(new signature_1.Signature(guardianSignature));
    }
    return transaction;
}
exports.applyTransactionSignature = applyTransactionSignature;
function getMetadata(metadata) {
    if (metadata) {
        return Object.assign(Object.assign({}, metadata), { url: utils_1.getAppMetadata().url });
    }
    return;
}
exports.getMetadata = getMetadata;
function sleep(timeout) {
    return __awaiter(this, void 0, void 0, function* () {
        return yield new Promise((resolve) => setTimeout(() => {
            resolve();
        }, timeout));
    });
}
exports.sleep = sleep;
