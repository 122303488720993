"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OptionalOperation = exports.Operation = void 0;
var Operation;
(function (Operation) {
    Operation["SIGN_TRANSACTION"] = "mvx_signTransaction";
    Operation["SIGN_TRANSACTIONS"] = "mvx_signTransactions";
    Operation["SIGN_MESSAGE"] = "mvx_signMessage";
})(Operation = exports.Operation || (exports.Operation = {}));
var OptionalOperation;
(function (OptionalOperation) {
    OptionalOperation["SIGN_LOGIN_TOKEN"] = "mvx_signLoginToken";
    OptionalOperation["SIGN_NATIVE_AUTH_TOKEN"] = "mvx_signNativeAuthToken";
    OptionalOperation["CANCEL_ACTION"] = "mvx_cancelAction";
})(OptionalOperation = exports.OptionalOperation || (exports.OptionalOperation = {}));
