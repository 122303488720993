export const environment = {
  domain:"https://t.f80.fr",
  mobile:true,
  height:"99vh",
  width:"100%",
  title:"The linKut App",
  production:false,
  appli:"linKut",
  appname:"linKut",
  server:"https://api.f80.fr:4242",
  shorter_service:"https://femis.f80.fr:444",
  transfer_page:"https://x.f80.fr",
  gate_server:"https://gate.nfluent.io/transfer",
  merchant:{},
  website:"https://f80.fr",
  faqs:"https://linkut.f80.fr/assets/faqs.yaml",
  company:"f80",
  version:0.18,
  connexion:{
    xAlias:false,
    address: true,
    direct_connect: false,
    email: false,
    extension_wallet: true,
    google: false,
    keystore: false,
    nfluent_wallet_connect: false,
    on_device: false,
    private_key: false,
    wallet_connect: true,
    web_wallet: false,
    webcam: false
  },
  max_file_size:1000000
}
