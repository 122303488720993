"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TransactionOptions = exports.TransactionVersion = void 0;
const constants_1 = require("./constants");
const errors = __importStar(require("./errors"));
class TransactionVersion {
    /**
     * Creates a TransactionVersion object given a value.
     */
    constructor(value) {
        value = Number(value);
        if (value < 1) {
            throw new errors.ErrTransactionVersionInvalid(value);
        }
        this.value = value;
    }
    /**
     * Creates a TransactionVersion object with the default version setting
     */
    static withDefaultVersion() {
        return new TransactionVersion(constants_1.TRANSACTION_VERSION_DEFAULT);
    }
    /**
     * Creates a TransactionVersion object with the VERSION setting for enabling options
     */
    static withTxOptions() {
        return new TransactionVersion(constants_1.TRANSACTION_VERSION_WITH_OPTIONS);
    }
    valueOf() {
        return this.value;
    }
}
exports.TransactionVersion = TransactionVersion;
class TransactionOptions {
    /**
     * Creates a TransactionOptions from a numeric value.
     */
    constructor(value) {
        value = Number(value);
        if (value < 0) {
            throw new errors.ErrTransactionOptionsInvalid(value);
        }
        this.value = value;
    }
    /**
     * Creates a TransactionOptions object with the default options.
     */
    static withDefaultOptions() {
        return new TransactionOptions(constants_1.TRANSACTION_OPTIONS_DEFAULT);
    }
    /**
     * Creates a TransactionOptions object from a set of options.
     */
    static withOptions(options) {
        let value = 0;
        if (options.hashSign) {
            value |= constants_1.TRANSACTION_OPTIONS_TX_HASH_SIGN;
        }
        if (options.guarded) {
            value |= constants_1.TRANSACTION_OPTIONS_TX_GUARDED;
        }
        return new TransactionOptions(value);
    }
    /**
     * Returns true if the "hash sign" option is set.
     */
    isWithHashSign() {
        return (this.value & constants_1.TRANSACTION_OPTIONS_TX_HASH_SIGN) == constants_1.TRANSACTION_OPTIONS_TX_HASH_SIGN;
    }
    /**
     * Returns true if the "guarded transaction" option is set.
     */
    isWithGuardian() {
        return (this.value & constants_1.TRANSACTION_OPTIONS_TX_GUARDED) == constants_1.TRANSACTION_OPTIONS_TX_GUARDED;
    }
    /**
     * Sets the "hash sign" option.
     */
    setWithHashSign() {
        this.value |= constants_1.TRANSACTION_OPTIONS_TX_HASH_SIGN;
    }
    /**
     * Sets the "guarded transaction" option.
     */
    setWithGuardian() {
        this.value |= constants_1.TRANSACTION_OPTIONS_TX_GUARDED;
    }
    valueOf() {
        return this.value;
    }
}
exports.TransactionOptions = TransactionOptions;
