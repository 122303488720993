<div style="width:fit-content;display: inline-block;padding:5px;">
  <webcam
  [height]="_size"
  [width]="_size"
  [trigger]="triggerObservable"
  [allowCameraSwitch]="false"
  [imageQuality]="imageQuality"
  [captureImageData]="true"
  imageType="image/jpeg"
  (click)="capture()"
  (imageClick)="capture()"
  (initError)="webcamError()"
  (imageCapture)="handleImage($event)">
</webcam><br>
  <span style="font-size: x-small">{{caption}}</span>

  <div *ngIf="showCapture" style="margin: 15px;">
    <button type="button" mat-raised-button
            title="Prendre la photo"
            (click)="capture()">
      Capture
    </button>
  </div>


  <div *ngIf="label_cancel!=''">
    <button mat-raised-button
            title="Eteindre la webcam"
            (click)="oncancel.emit()">
      {{label_cancel}}
    </button>
  </div>


</div>

