"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TransactionHash = exports.Transaction = void 0;
const bignumber_js_1 = require("bignumber.js");
const address_1 = require("./address");
const compatibility_1 = require("./compatibility");
const constants_1 = require("./constants");
const errors = __importStar(require("./errors"));
const hash_1 = require("./hash");
const networkParams_1 = require("./networkParams");
const proto_1 = require("./proto");
const signature_1 = require("./signature");
const transactionPayload_1 = require("./transactionPayload");
const utils_1 = require("./utils");
const createTransactionHasher = require("blake2b");
const TRANSACTION_HASH_LENGTH = 32;
/**
 * An abstraction for creating, signing and broadcasting transactions.
 */
class Transaction {
    /**
     * Creates a new Transaction object.
     */
    constructor({ nonce, value, sender, receiver, senderUsername, receiverUsername, gasPrice, gasLimit, data, chainID, version, options, guardian, }) {
        this.nonce = nonce || 0;
        this.value = value ? new bignumber_js_1.BigNumber(value.toString()).toFixed(0) : 0;
        this.sender = sender;
        this.receiver = receiver;
        this.senderUsername = senderUsername || "";
        this.receiverUsername = receiverUsername || "";
        this.gasPrice = gasPrice || constants_1.TRANSACTION_MIN_GAS_PRICE;
        this.gasLimit = gasLimit;
        this.data = data || new transactionPayload_1.TransactionPayload();
        this.chainID = chainID;
        this.version = version ? new networkParams_1.TransactionVersion(version.valueOf()) : networkParams_1.TransactionVersion.withDefaultVersion();
        this.options = options ? new networkParams_1.TransactionOptions(options.valueOf()) : networkParams_1.TransactionOptions.withDefaultOptions();
        this.guardian = guardian || address_1.Address.empty();
        this.signature = Buffer.from([]);
        this.guardianSignature = Buffer.from([]);
        this.hash = TransactionHash.empty();
    }
    getNonce() {
        return this.nonce;
    }
    /**
     * Sets the account sequence number of the sender. Must be done prior signing.
     */
    setNonce(nonce) {
        this.nonce = nonce;
    }
    getValue() {
        return this.value;
    }
    setValue(value) {
        this.value = value;
    }
    getSender() {
        return this.sender;
    }
    setSender(sender) {
        this.sender = sender;
    }
    getReceiver() {
        return this.receiver;
    }
    getSenderUsername() {
        return this.senderUsername;
    }
    setSenderUsername(senderUsername) {
        this.senderUsername = senderUsername;
    }
    getReceiverUsername() {
        return this.receiverUsername;
    }
    setReceiverUsername(receiverUsername) {
        this.receiverUsername = receiverUsername;
    }
    getGuardian() {
        return this.guardian;
    }
    getGasPrice() {
        return this.gasPrice;
    }
    setGasPrice(gasPrice) {
        this.gasPrice = gasPrice;
    }
    getGasLimit() {
        return this.gasLimit;
    }
    setGasLimit(gasLimit) {
        this.gasLimit = gasLimit;
    }
    getData() {
        return this.data;
    }
    getChainID() {
        return this.chainID;
    }
    setChainID(chainID) {
        this.chainID = chainID;
    }
    getVersion() {
        return this.version;
    }
    setVersion(version) {
        this.version = new networkParams_1.TransactionVersion(version.valueOf());
    }
    getOptions() {
        // Make sure that "sdk-core v12" is compatible (for a while) with (older) libraries that were previously setting the (soon to be private) "options" field directly,
        // instead of using the "setOptions()" method.
        const options = new networkParams_1.TransactionOptions(this.options.valueOf());
        return options;
    }
    setOptions(options) {
        this.options = new networkParams_1.TransactionOptions(options.valueOf());
    }
    getSignature() {
        return this.signature;
    }
    getGuardianSignature() {
        return this.guardianSignature;
    }
    setGuardian(guardian) {
        this.guardian = guardian;
    }
    getHash() {
        utils_1.guardNotEmpty(this.hash, "hash");
        return this.hash;
    }
    /**
     * Serializes a transaction to a sequence of bytes, ready to be signed.
     * This function is called internally by signers.
     */
    serializeForSigning() {
        // TODO: for appropriate tx.version, interpret tx.options accordingly and sign using the content / data hash
        let plain = this.toPlainObject();
        // Make sure we never sign the transaction with another signature set up (useful when using the same method for verification)
        if (plain.signature) {
            delete plain.signature;
        }
        if (plain.guardianSignature) {
            delete plain.guardianSignature;
        }
        if (!plain.guardian) {
            delete plain.guardian;
        }
        let serialized = JSON.stringify(plain);
        return Buffer.from(serialized);
    }
    /**
     * Checks the integrity of the guarded transaction
     */
    isGuardedTransaction() {
        const hasGuardian = this.guardian.bech32().length > 0;
        const hasGuardianSignature = this.guardianSignature.length > 0;
        return this.getOptions().isWithGuardian() && hasGuardian && hasGuardianSignature;
    }
    /**
     * Converts the transaction object into a ready-to-serialize, plain JavaScript object.
     * This function is called internally within the signing procedure.
     */
    toPlainObject() {
        var _a;
        const plainObject = {
            nonce: this.nonce.valueOf(),
            value: this.value.toString(),
            receiver: this.receiver.bech32(),
            sender: this.sender.bech32(),
            senderUsername: this.senderUsername ? Buffer.from(this.senderUsername).toString("base64") : undefined,
            receiverUsername: this.receiverUsername ? Buffer.from(this.receiverUsername).toString("base64") : undefined,
            gasPrice: this.gasPrice.valueOf(),
            gasLimit: this.gasLimit.valueOf(),
            data: this.data.length() == 0 ? undefined : this.data.encoded(),
            chainID: this.chainID.valueOf(),
            version: this.getVersion().valueOf(),
            options: this.getOptions().valueOf() == 0 ? undefined : this.getOptions().valueOf(),
            guardian: ((_a = this.guardian) === null || _a === void 0 ? void 0 : _a.bech32()) ? (this.guardian.bech32() == "" ? undefined : this.guardian.bech32()) : undefined,
            signature: this.signature.toString("hex") ? this.signature.toString("hex") : undefined,
            guardianSignature: this.guardianSignature.toString("hex") ? this.guardianSignature.toString("hex") : undefined,
        };
        compatibility_1.Compatibility.guardAddressIsSetAndNonZero(new address_1.Address(plainObject.sender), "'sender' of transaction", "pass the actual sender to the Transaction constructor");
        return plainObject;
    }
    /**
     * Converts a plain object transaction into a Transaction Object.
     *
     * @param plainObjectTransaction Raw data of a transaction, usually obtained by calling toPlainObject()
     */
    static fromPlainObject(plainObjectTransaction) {
        const tx = new Transaction({
            nonce: Number(plainObjectTransaction.nonce),
            value: new bignumber_js_1.BigNumber(plainObjectTransaction.value).toFixed(0),
            receiver: address_1.Address.fromString(plainObjectTransaction.receiver),
            receiverUsername: plainObjectTransaction.receiverUsername ? Buffer.from(plainObjectTransaction.receiverUsername, "base64").toString() : undefined,
            sender: address_1.Address.fromString(plainObjectTransaction.sender),
            senderUsername: plainObjectTransaction.senderUsername ? Buffer.from(plainObjectTransaction.senderUsername, "base64").toString() : undefined,
            guardian: plainObjectTransaction.guardian ? address_1.Address.fromString(plainObjectTransaction.guardian) : undefined,
            gasPrice: Number(plainObjectTransaction.gasPrice),
            gasLimit: Number(plainObjectTransaction.gasLimit),
            data: new transactionPayload_1.TransactionPayload(Buffer.from(plainObjectTransaction.data || "", "base64")),
            chainID: String(plainObjectTransaction.chainID),
            version: new networkParams_1.TransactionVersion(plainObjectTransaction.version),
            options: plainObjectTransaction.options != null ? new networkParams_1.TransactionOptions(plainObjectTransaction.options) : undefined
        });
        if (plainObjectTransaction.signature) {
            tx.applySignature(new signature_1.Signature(plainObjectTransaction.signature));
        }
        if (plainObjectTransaction.guardianSignature) {
            tx.applyGuardianSignature(new signature_1.Signature(plainObjectTransaction.guardianSignature));
        }
        return tx;
    }
    /**
     * Applies the signature on the transaction.
     *
     * @param signature The signature, as computed by a signer.
     */
    applySignature(signature) {
        this.signature = signature_1.interpretSignatureAsBuffer(signature);
        this.hash = TransactionHash.compute(this);
    }
    /**
     * Applies the guardian signature on the transaction.
     *
     * @param guardianSignature The signature, as computed by a signer.
     */
    applyGuardianSignature(guardianSignature) {
        this.guardianSignature = signature_1.interpretSignatureAsBuffer(guardianSignature);
        this.hash = TransactionHash.compute(this);
    }
    /**
     * Converts a transaction to a ready-to-broadcast object.
     * Called internally by the network provider.
     */
    toSendable() {
        return this.toPlainObject();
    }
    /**
     * Computes the current transaction fee based on the {@link NetworkConfig} and transaction properties
     * @param networkConfig {@link NetworkConfig}
     */
    computeFee(networkConfig) {
        let moveBalanceGas = networkConfig.MinGasLimit.valueOf() +
            this.data.length() * networkConfig.GasPerDataByte.valueOf();
        if (moveBalanceGas > this.gasLimit.valueOf()) {
            throw new errors.ErrNotEnoughGas(this.gasLimit.valueOf());
        }
        let gasPrice = new bignumber_js_1.BigNumber(this.gasPrice.valueOf());
        let feeForMove = new bignumber_js_1.BigNumber(moveBalanceGas).multipliedBy(gasPrice);
        if (moveBalanceGas === this.gasLimit.valueOf()) {
            return feeForMove;
        }
        let diff = new bignumber_js_1.BigNumber(this.gasLimit.valueOf() - moveBalanceGas);
        let modifiedGasPrice = gasPrice.multipliedBy(new bignumber_js_1.BigNumber(networkConfig.GasPriceModifier.valueOf()));
        let processingFee = diff.multipliedBy(modifiedGasPrice);
        return feeForMove.plus(processingFee);
    }
}
exports.Transaction = Transaction;
/**
 * An abstraction for handling and computing transaction hashes.
 */
class TransactionHash extends hash_1.Hash {
    constructor(hash) {
        super(hash);
    }
    /**
     * Computes the hash of a transaction.
     */
    static compute(transaction) {
        let serializer = new proto_1.ProtoSerializer();
        let buffer = serializer.serializeTransaction(transaction);
        let hash = createTransactionHasher(TRANSACTION_HASH_LENGTH)
            .update(buffer)
            .digest("hex");
        return new TransactionHash(hash);
    }
}
exports.TransactionHash = TransactionHash;
