"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Logger = void 0;
class Logger {
    static error(message) {
        console.error(message);
    }
    static trace(message) {
        console.trace(message);
    }
}
exports.Logger = Logger;
