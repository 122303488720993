"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WALLET_PROVIDER_CALLBACK_PARAM_TX_SIGNED = exports.WALLET_PROVIDER_CALLBACK_PARAM = exports.WALLET_PROVIDER_SIGN_MESSAGE_URL = exports.WALLET_PROVIDER_GUARD_TRANSACTION_URL = exports.WALLET_PROVIDER_SIGN_TRANSACTION_URL = exports.WALLET_PROVIDER_SEND_TRANSACTION_URL = exports.WALLET_PROVIDER_DISCONNECT_URL = exports.WALLET_PROVIDER_CONNECT_URL = exports.XALIAS_PROVIDER_TESTNET = exports.XALIAS_PROVIDER_DEVNET = exports.XALIAS_PROVIDER_MAINNET = exports.WALLET_PROVIDER_TESTNET = exports.WALLET_PROVIDER_DEVNET = exports.WALLET_PROVIDER_MAINNET = void 0;
exports.WALLET_PROVIDER_MAINNET = "https://wallet.multiversx.com/dapp/init";
exports.WALLET_PROVIDER_DEVNET = "https://devnet-wallet.multiversx.com/dapp/init";
exports.WALLET_PROVIDER_TESTNET = "https://testnet-wallet.multiversx.com/dapp/init";
exports.XALIAS_PROVIDER_MAINNET = "https://xalias.com";
exports.XALIAS_PROVIDER_DEVNET = "https://devnet.xalias.com";
exports.XALIAS_PROVIDER_TESTNET = "https://testnet.xalias.com";
exports.WALLET_PROVIDER_CONNECT_URL = "hook/login";
exports.WALLET_PROVIDER_DISCONNECT_URL = "hook/logout";
exports.WALLET_PROVIDER_SEND_TRANSACTION_URL = "hook/transaction";
exports.WALLET_PROVIDER_SIGN_TRANSACTION_URL = "hook/sign";
exports.WALLET_PROVIDER_GUARD_TRANSACTION_URL = "hook/2fa";
exports.WALLET_PROVIDER_SIGN_MESSAGE_URL = "hook/sign-message";
exports.WALLET_PROVIDER_CALLBACK_PARAM = "walletProviderStatus";
exports.WALLET_PROVIDER_CALLBACK_PARAM_TX_SIGNED = "transactionsSigned";
