<div style="width: 100%;text-align: center;position: relative;" *ngIf="network">

  <div class="mat-subheading-1">{{title}}</div>

  <!--  Permet l'authentification force par code d'accés ou directement seed phrase-->
  <mat-accordion *ngIf="!directShowQRCode">
    <mat-expansion-panel *ngIf="network.indexOf('elrond')>-1 && (showWalletConnect || showExtensionWallet || showWebWallet)" [expanded]="walletconnect_open">
      <mat-expansion-panel-header class="color-primary">Se connecter avec son wallet</mat-expansion-panel-header>
      <mat-card style="text-align: center;width: fit-content;display: inline-block;" class="multiverx-background" *ngIf="qrcode.length==0">
        <mat-card-title class="multiversx-text">S'authentifier sur MultiversX via</mat-card-title>
        <button mat-raised-button class="multiversx-button"
                *ngIf="connexion.wallet_connect && qrcode.length==0"
                (click)="open_wallet_connect()">
          L'application xPortal
        </button>

        <button mat-raised-button class="multiversx-button"
                *ngIf="connexion.web_wallet"
                (click)="open_web_wallet()">
          Le wallet du Web
        </button>

        <button mat-raised-button class="multiversx-button"
                *ngIf="connexion.xAlias"
                (click)="open_web_wallet('xAlias')">
          Avec Google
        </button>

        <button mat-raised-button class="multiversx-button"
                *ngIf="connexion.extension_wallet"
                (click)="open_extension_wallet()">
          L'extension Chrome
        </button>

      </mat-card>

      <br>

      <div *ngIf="showWalletConnect" style="max-width: 450px;margin-left: -10px;width:100%;text-align: center;display: inline-block;">
        {{intro_message}}
        <img [src]="qrcode"
             *ngIf="qrcode.length>0"
             (click)="qrcode=''"
             title="Flasher ce qrcode depuis votre wallet"
             style="width:90%;max-width: 250px;margin-top:5px;" [style.width]="size">
        <br>

        <button mat-raised-button
                *ngIf="device.modele!='desktop' && showDirectConnect && showWalletConnect && url_xportal_direct_connect!=''"
                (click)="open_xportal()">
          Basculer sur xPortal
        </button>

      </div>

    </mat-expansion-panel>
    <mat-expansion-panel *ngIf="showNfluentWalletConnect">
      <mat-expansion-panel-header class="color-primary">Utiliser le wallet NFluent</mat-expansion-panel-header>
      <button   color="primary"
              mat-raised-button
              (click)="active_webcam()" >
        Utiliser mon QRCode
      </button>

      <br>
      <app-scanner *ngIf="enabled_webcam"
                   [size]="size"
                   title="Montrer le QRcode de votre wallet à cette caméra"
                   (flash)="onflash($event)"
                   filter=""
                   (cancel)="cancel_webcam()"
                   (click)="update_dynamic_token()">
      </app-scanner>

      <!--  voir les commentaire sur le dynamic token dans le fichier d'opération de référence-->
      <div *ngIf="showDynamicToken">
        <button   color="primary"
                mat-raised-button
                mat-button
                (click)="update_dynamic_token()" >
          Coller le token
        </button>
      </div>

      <div style="width: fit-content;display: inline-block;margin:20px;">
        <img
                title="Flasher avec votre wallet nfluent"
                [cdkCopyToClipboard]="nfluent_wallet_connect_qrcode"
                [src]="nfluent_wallet_connect_qrcode"
                style="cursor: copy;max-width: 90%;"
                [style.width]="size"><br>
        <small>Flashez depuis votre wallet NFluent</small>
      </div>

    </mat-expansion-panel>
    <mat-expansion-panel *ngIf="showAccesCode || showPrivateKey">
      <mat-expansion-panel-header>Utiliser votre clé secrète</mat-expansion-panel-header>
      <div style="max-width: 500px;width:100%;display: inline-block;text-align: center;">
        <app-input *ngIf="showAccesCode"
                   [value]="access_code"
                   (valueChange)="access_code=$event"
                   (validate)="connect('code')" label_button="Valider"
                   help="Ce code se trouve dans le mail reçu à la création de votre wallet"
                   label="Votre code d'accès">
        </app-input>
        <app-input *ngIf="showPrivateKey"
                   (valueChange)="private_key=$event" label_button="Valider"
                   [value]="private_key" (validate)="connect('private_key')"
                   help="Cette phrase vous a été envoyé dans le mail de création"
                   label="Votre phrase secrete">
        </app-input>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel *ngIf="showKeystore">
      <mat-expansion-panel-header>Utiliser le KeyStore</mat-expansion-panel-header>
      <app-upload-file extensions=".json"
                       title="Charger le fichier json"
                       [zone]="true"
                       width="100%"
                       height="60px"
                       (uploaded)="upload_keystore($event)">
        Faites glisser le fichier json ici
      </app-upload-file>
    </mat-expansion-panel>

    <mat-expansion-panel *ngIf="showEmail || showGoogle || showAddress || showWebcam">
      <mat-expansion-panel-header>Utiliser votre email ou adresse de wallet</mat-expansion-panel-header>

      <app-scanner *ngIf="enabled_webcam && showWebcam"
                   size="200px"
                   title="Montrer le QRcode de votre wallet à cette caméra"
                   (flash)="on_retreive_address($event)"
                   (cancel)="cancel_webcam()">
      </app-scanner>

      <div class="expansion-content" *ngIf="!enabled_webcam">

        <!--    voir https://www.npmjs.com/package/@abacritt/angularx-social-login-->

        <asl-google-signin-button *ngIf="showGoogle" style="height: 50px;"
                                  title="Authentification simplifiée pour les utisateurs de gmail">
        </asl-google-signin-button>

        <app-input
                *ngIf="connexion.email || connexion.address"
                maxwidth="600px" style="width: 80%"
                [label]="intro_message"
                [placeholder]="prompt"
                [value]="address" (valueChange)="update_address($event)"
                (validate)="validate($event)"
                label_button="Valider"
                [help]="explain_message">
        </app-input>

        <button mat-icon-button (click)="run_scanner()" *ngIf="showWebcam" style="margin-top: -10px;">
          <mat-icon>qr_code_scanner</mat-icon>
        </button>

      </div>
    </mat-expansion-panel>

  </mat-accordion>

  <!--  https://css-tricks.com/snippets/css/a-guide-to-flexbox/ -->

  <mat-expansion-panel *ngIf="network.indexOf('polygon')>-1 && showExtensionWallet" class="polygon-background">
    <mat-expansion-panel-header class="color-primary">Authentification Polygon</mat-expansion-panel-header>
    <button   mat-raised-button class="polygon-button"
            (click)="open_polygon_extension_wallet()">
      Extension Chrome
    </button>
  </mat-expansion-panel>

  <div *ngIf="directShowQRCode && network.indexOf('elrond')>-1">
    <div *ngIf="nfluent_wallet_connect_qrcode.length>0 && showNfluentWalletConnect"
         [style.opacity]="qrcode_enabled ? 1 : 0.5" >
      <img title="Flasher avec votre wallet nfluent"
           [cdkCopyToClipboard]="nfluent_wallet_connect_qrcode"
           [src]="nfluent_wallet_connect_qrcode"
           style="cursor: copy;" [style.width]="size"><br>
      <small>{{title}}</small>
    </div>

    {{intro_message}}<br>
    <img [src]="qrcode"
         title="Flasher ce qrcode depuis votre wallet"
         style="width:90%;max-width: 600px;margin-top:10px;" [style.width]="size">
  </div>

  <!--  <div style="margin-top:15px;text-align: center;width: 100%;" *ngIf="address.length<20" >-->
  <!--    <div *ngIf="showNetwork">-->
  <!--      Se connecter avec votre wallet sur<br>-->
  <!--      <button   mat-raised-button -->
  <!--              *ngIf="api.isSolana(network) && (api.installed_wallet().indexOf('phantom')>-1 || api.installed_wallet().indexOf('solflare')>-1)"-->
  <!--              (click)="open_wallet()">-->
  <!--        Solana-->
  <!--      </button>-->

  <!--      <button   mat-raised-button -->
  <!--              *ngIf="api.installed_wallet().indexOf('maiar')>-1"-->
  <!--              (click)="open_wallet()">-->
  <!--        Elrond-->
  <!--      </button>-->
  <!--    </div>-->
  <!--  </div>-->


  <div id="cmdPanel" *ngIf="!directShowQRCode" style="margin-left:10%;margin-top:20px;width: 80%;text-align: center;" >
    <button   mat-raised-button
            title="Ne pas se connecter" *ngIf="showCancel"
            (click)="cancel()">
      Annuler
    </button>
  </div>
</div>