"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NativeAuthClientConfig = void 0;
class NativeAuthClientConfig {
    constructor() {
        this.origin = typeof window !== "undefined" && typeof window.location !== 'undefined' ? window.location.hostname : '';
        this.apiUrl = 'https://api.multiversx.com';
        this.expirySeconds = 60 * 60 * 24;
    }
}
exports.NativeAuthClientConfig = NativeAuthClientConfig;
